import React, { useEffect, useState } from 'react'

const Footer = () => {

     // State to track window dimensions
      const [windowDimensions, setWindowDimensions] = useState({
        width: typeof window !== "undefined" ? window.innerWidth : 1200,
        height: typeof window !== "undefined" ? window.innerHeight : 800,
      })
    
      // Update dimensions on window resize
      useEffect(() => {
        const handleResize = () => {
          setWindowDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
          })
        }
    
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
      }, [])
    
      // Determine if mobile view
      const isMobile = windowDimensions.width < 768
      const isTablet = windowDimensions.width >= 768 && windowDimensions.width < 1024

    const styles = {
        // Footer
    footer: {
        backgroundColor: "#070808",
        color: "#ccc",
        padding: isMobile ? "40px 0 20px" : "60px 0 20px",
      },
      footerContainer: {
        width: "100%",
        maxWidth: "1200px",
        margin: "0 auto",
        padding: "0 20px",
      },
      footerContent: {
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        flexWrap: "wrap",
        gap: isMobile ? "30px" : "40px",
        justifyContent: "space-between",
      },
      footerLeft: {
        flex: isMobile ? "1 1 100%" : "1 1 60%",
        maxWidth: isMobile ? "100%" : "600px",
      },
      footerLogo: {
        maxWidth: "150px",
        marginBottom: "20px",
      },
      footerText: {
        marginBottom: "20px",
        lineHeight: 1.6,
        fontSize: "14px",
      },
      footerLinks: {
        display: "flex",
        gap: "20px",
        marginBottom: "30px",
      },
      footerLink: {
        color: "#ff0000",
        textDecoration: "none",
        transition: "color 0.3s ease",
      },
      footerRight: {
        flex: isMobile ? "1 1 100%" : "0 1 30%",
      },
      footerHeading: {
        fontSize: "18px",
        marginBottom: "15px",
        fontWeight: "bold",
      },
      copyright: {
        borderTop: "1px solid #333",
        marginTop: "40px",
        paddingTop: "20px",
        textAlign: "center",
        fontSize: "14px",
      },
    }
  return (
     <>
        {/* Footer */}
      <div style={styles.footer}>
        <div style={styles.footerContainer}>
          <div style={styles.footerContent}>
            <div style={styles.footerLeft}>
              <div style={styles.footerLogo}>
                <img src="/images/logo.png" alt="Company Logo" style={{ maxWidth: "100%" }} />
              </div>
              <div style={styles.footerText}>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aspernatur corrupti, voluptas facere eos,
                neque provident in aut, repellat architecto doloremque ipsa. Dolorum reprehenderit totam vero adipisci
                quos sapiente nostrum placeat!
              </div>
              <div style={styles.footerLinks}>
                <a href="/privacy-policy" style={styles.footerLink}>
                  Privacy Policy
                </a>
                <a href="/terms-of-service" style={styles.footerLink}>
                  Terms & Service
                </a>
                <a href="/contact-us" style={styles.footerLink}>
                  Contact Us
                </a>
                {/* <a href="/about-us" style={styles.footerLink}>
                  About Us
                </a> */}
              </div>
            </div>
            <div style={styles.footerRight}>
              <div style={styles.footerHeading}>Reach Us</div>
              <a href="mailto:sales@uhrig.com" style={styles.footerLink}>
              admin@daten-kompass.de
              </a>
            </div>
          </div>
          <div style={styles.copyright}>Copyright © 2025 daten-kompass.de - All Rights Reserved.</div>
        </div>
      </div>
     </>
  )
}

export default Footer