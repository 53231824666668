import { useState, useEffect } from "react";
import { Check, Coins, CreditCard, Package, Percent } from 'lucide-react';
import { useNavigate } from "react-router-dom"
import config from '../../lib/config'

const AddBalance = () => {
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    const fetchPackages = async () => {
      const token = localStorage.getItem("access");
      if (!token) {
        alert("User is not authenticated!");
        return;
      }

      try {
        const response = await fetch(`${config.BASE_URL}/api/jobs/get_product_list/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if(response.status === 401){
          navigate('/')
        }
    

        const data = await response.json();

        if (response.ok) {
          setPackages(data.products);  // Assuming the response contains the list of packages
          setSelectedPackage(data[0]);  // Default to the first package
        } else {
          alert(`Error: ${data.message || "Failed to fetch packages."}`);
        }
      } catch (error) {
        console.error("Error fetching packages:", error);
        alert("Something went wrong. Please try again.");
      }
    };

    fetchPackages();
  }, []);

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleBuy = async () => {
    if (!selectedPackage) return;

    const token = localStorage.getItem("access");
    if (!token) {
      alert("User is not authenticated!");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${config.BASE_URL}/wallet/add_coins/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          productId: selectedPackage.id,  // Send the product ID instead of price and coins
        }),
      });

      if(response.status === 401){
        navigate('/')
      }
  

      const data = await response.json();

      if (response.ok) {
        alert(`Successfully purchased ${selectedPackage.coins} coins!`);
      } else {
        alert(`Error: ${data.message || "Failed to process the purchase."}`);
      }
    } catch (error) {
      console.error("Error buying coins:", error);
      alert("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: "1000px", margin: "auto", padding: "24px", background: "#070808", color: "#B9B9B9" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "24px" }}>
        <Coins size={32} color="#E84018" />
        <h1 style={{ fontSize: "24px", fontWeight: "bold", color: "#fff" }}>Add Coins</h1>
      </div>

      <div style={{ background: "#121212", borderRadius: "12px", boxShadow: "0 4px 6px rgba(0,0,0,0.3)", padding: "24px", marginBottom: "24px" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "24px" }}>
          <div>
            <span style={{ fontSize: "12px", color: "#B9B9B9" }}>Selected Package</span>
            <div style={{ display: "flex", alignItems: "center", gap: "8px", marginTop: "4px" }}>
              <span style={{ fontSize: "20px", fontWeight: "bold", color: "#fff" }}>{formatNumber(selectedPackage?.coins || 0)} Coins</span>
              <span style={{ fontSize: "16px", color: "#B9B9B9" }}>for {selectedPackage?.price || 0}€</span>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "8px", background: "rgba(232, 64, 24, 0.15)", color: "#E84018", padding: "8px 16px", borderRadius: "50px" }}>
            {selectedPackage?.discount > 0 ? (
              <>
                <Percent size={16} />
                <span style={{ fontWeight: "500" }}>{selectedPackage.discount}% Discount</span>
              </>
            ) : (
              <>
                <Package size={16} />
                <span style={{ fontWeight: "500" }}>Minimum Purchase</span>
              </>
            )}
          </div>
        </div>

        <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))", gap: "16px", marginBottom: "24px" }}>
          {packages.map((pkg, index) => (
            <div
              key={index}
              style={{
                position: "relative",
                border: `2px solid ${selectedPackage?.coins === pkg.coins ? "#E84018" : "#333"}`,
                borderRadius: "12px",
                padding: "16px",
                cursor: "pointer",
                background: selectedPackage?.coins === pkg.coins ? "rgba(232, 64, 24, 0.1)" : "#1A1A1A",
                transition: "all 0.3s ease-in-out",
              }}
              onClick={() => setSelectedPackage(pkg)}
            >
              {selectedPackage?.coins === pkg.coins && (
                <div style={{ position: "absolute", top: "-8px", right: "-8px", background: "#E84018", color: "white", borderRadius: "50%", padding: "4px" }}>
                  <Check size={16} />
                </div>
              )}
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "8px" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <Coins size={20} color={pkg.discount >= 10 ? "#E84018" : "#B9B9B9"} />
                  <span style={{ fontWeight: "bold", fontSize: "16px", color: "#fff" }}>{formatNumber(pkg.coins)}</span>
                </div>
                {pkg.discount > 0 && (
                  <span style={{ background: "rgba(232, 64, 24, 0.15)", color: "#E84018", fontSize: "12px", padding: "4px 8px", borderRadius: "12px" }}>
                    {pkg.discount}% OFF
                  </span>
                )}
              </div>
              <div style={{ display: "flex", alignItems: "baseline", gap: "8px", marginBottom: "4px" }}>
                <span style={{ fontWeight: "bold", fontSize: "18px", color: "#fff" }}>{pkg.price}€</span>
                {pkg.discount > 0 && <span style={{ color: "#B9B9B9", fontSize: "14px", textDecoration: "line-through" }}>{pkg.originalPrice}€</span>}
              </div>
              <div style={{ fontSize: "12px", color: "#B9B9B9" }}>{(pkg.price / pkg.coins).toFixed(3)}€ per coin</div>
            </div>
          ))}
        </div>

        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderTop: "1px solid #333", paddingTop: "16px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "8px", color: "#B9B9B9" }}>
            <CreditCard size={20} />
            <span>Secure payment via master and visa</span>
          </div>
          <button 
            onClick={handleBuy} 
            disabled={loading}
            style={{ background: "#E84018", color: "white", padding: "12px 24px", borderRadius: "8px", fontWeight: "500", cursor: "pointer", border: "none" }}>
            {loading ? "Processing..." : `Buy ${formatNumber(selectedPackage?.coins || 0)} Coins`}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddBalance;
