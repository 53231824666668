import React from 'react';

function Profile() {
  return (
    <div>
      <h1>Profile</h1>
      <p>View and edit your profile information here.</p>
    </div>
  );
}

export default Profile;
