import React from 'react';
import { Outlet } from 'react-router-dom'; // For nested routes

const MenuBar = () => {
  return (
    <div className="top-menu-bar">
        <div className="custom-container">
            <div className="top-menu-bar-main">
                <div className="site-logo">
                    <img src="/images/logo.png" alt="" />
                </div>
                <div className="top-navs">
                    <a href="/login" className='site-cta outlined'>Login</a>
                    <a href="/register" className='site-cta filled'>Sign up</a>
                </div>
            </div>
        </div>
    </div>
  );
};

export default MenuBar;
