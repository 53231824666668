


import { useContext, useState } from "react"
import { AppContext } from "../../context/AppContext"
import { useNavigate } from "react-router-dom"
import { Upload, FileText, Download, ArrowRight } from "lucide-react"
import config from '../../lib/config'

const AddFile = () => {
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const { setEstimation_time, setTotal_records, setEstimation_cost, setFile, setJobName, file, jobName } =
    useContext(AppContext)

  const handleFileChange = (e) => {
    const selectedFile = e.target.files?.[0] || null

    if (selectedFile) {
      if (!selectedFile.name.endsWith(".csv")) {
        setErrors((prev) => ({ ...prev, file: "Only CSV files are allowed" }))
        setFile(null)
      } else {
        setErrors((prev) => ({ ...prev, file: undefined }))
        setFile(selectedFile)
      }
    }
  }

  const handleJobNameChange = (e) => {
    setJobName(e.target.value)
    if (e.target.value.trim()) {
      setErrors((prev) => ({ ...prev, jobName: undefined }))
    }
  }

  const validateForm = () => {
    const newErrors = {}

    if (!jobName?.trim()) {
      newErrors.jobName = "Job name is required"
    }
    if (!file) {
      newErrors.file = "Please upload a CSV file"
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const token = localStorage.getItem("access")

    if (!token) {
      return
    }

    if (!validateForm()) {
      setErrors((prev) => ({ ...prev, api: "validation error" }))
      return
    }

    setIsLoading(true)

    try {
      const formData = new FormData()
      formData.append("file", file)
      formData.append("job_name", jobName)

      const response = await fetch(`${config.BASE_URL}/api/jobs/getEstimation/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })

      if(response.status === 401){
        navigate('/')
      }

      const data = await response.json()

      if (data.success === false && response.status === 400) {
        setErrors((prev) => ({ ...prev, api: data.error }))
        return
      }

      if (typeof data !== "object" || data === null) {
        throw new Error("Invalid API response")
      }

      setEstimation_time(data.estimation_time)
      setTotal_records(data.total_records)
      setEstimation_cost(data.estimation_cost)

      navigate("/add-file/summary")
    } catch (error) {
      console.error("Error:", error)
      setErrors((prev) => ({ ...prev, api: "Failed to get estimation. Please try again." }))
    } finally {
      setIsLoading(false)
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleDrop = (e) => {
    e.preventDefault()

    const droppedFile = e.dataTransfer.files[0]

    if (droppedFile) {
      if (!droppedFile.name.endsWith(".csv")) {
        setErrors((prev) => ({ ...prev, file: "Only CSV files are allowed" }))
        setFile(null)
      } else {
        setErrors((prev) => ({ ...prev, file: undefined }))
        setFile(droppedFile)
      }
    }
  }

  const downloadSampleCSV = () => {
    const csvContent =
      "Article Number,ASIN,EAN\nLPNHE343106293,B0B5W1CQ8W,718037893501\nLPNHE914186944,B00371R8P4,3362932913771"

    const blob = new Blob([csvContent], { type: "text/csv" })
    const url = URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url
    a.download = "sample_product_list.csv"
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
  }

  const containerStyle = {
    padding: "20px",
    color: "#fff",
    maxWidth: "1200px",
    margin: "0 auto",
  }

  const titleStyle = {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
  }

  const formContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  }

  const labelStyle = {
    fontSize: "16px",
    marginBottom: "8px",
    display: "block",
  }

  const inputStyle = {
    width: "100%",
    padding: "12px 16px",
    backgroundColor: "#1E1E1E",
    border: errors.jobName ? "1px solid #FF4D4F" : "1px solid #333",
    borderRadius: "4px",
    color: "#fff",
    fontSize: "14px",
  }

  const cardStyle = {
    backgroundColor: "#121212",
    borderRadius: "8px",
    padding: "20px",
    marginTop: "20px",
  }

  const descriptionStyle = {
    color: "#999",
    marginBottom: "20px",
    fontSize: "14px",
  }

  const uploadAreaContainerStyle = {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    flexWrap: "wrap",
  }

  const uploadAreaStyle = {
    flex: "1",
    minWidth: "300px",
  }

  const dragDropStyle = {
    border: file ? "1px solid #444" : errors.file ? "1px dashed #FF4D4F" : "1px dashed #444",
    borderRadius: "4px",
    padding: "40px 20px",
    textAlign: "center",
    cursor: "pointer",
    backgroundColor: "#1A1A1A",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "200px",
  }

  const iconContainerStyle = {
    marginBottom: "16px",
  }

  const instructionsBoxStyle = {
    flex: "1",
    backgroundColor: "#000",
    borderRadius: "8px",
    padding: "20px",
    minWidth: "300px",
  }

  const instructionsTitleStyle = {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "16px",
  }

  const instructionsTextStyle = {
    color: "#999",
    fontSize: "14px",
    marginBottom: "8px",
  }

  const actionsStyle = {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    flexWrap: "wrap",
    gap: "10px",
  }

  const downloadButtonStyle = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "10px 16px",
    backgroundColor: "transparent",
    border: "none",
    color: "#4D7CFE",
    cursor: "pointer",
    fontSize: "14px",
  }

  const nextButtonStyle = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "10px 20px",
    backgroundColor: "#E84118",
    border: "none",
    borderRadius: "4px",
    color: "white",
    cursor: isLoading ? "not-allowed" : "pointer",
    fontSize: "14px",
    opacity: isLoading ? 0.7 : 1,
  }

  const errorMessageStyle = {
    color: "#FF4D4F",
    fontSize: "12px",
    marginTop: "4px",
  }

  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>Add File</h1>

      <div style={formContainerStyle}>
        <div>
          <label htmlFor="jobName" style={labelStyle}>
            Job Name
          </label>
          <input
            type="text"
            id="jobName"
            style={inputStyle}
            placeholder="Enter Job Name"
            value={jobName || ""}
            onChange={handleJobNameChange}
          />
          {errors.jobName && <p style={errorMessageStyle}>{errors.jobName}</p>}
        </div>

        <div style={cardStyle}>
          <p style={descriptionStyle}>Upload a CSV file containing product EAN/GTIN for search</p>

          <div style={uploadAreaContainerStyle}>
            <div style={uploadAreaStyle}>
              <div
                style={dragDropStyle}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onClick={() => document.getElementById("fileInput")?.click()}
              >
                <div style={iconContainerStyle}>
                  {file ? <FileText size={40} color="#666" /> : <Upload size={40} color="#666" />}
                </div>
                <p style={{ color: "#999", fontSize: "14px" }}>
                  {file ? file.name : "Drop your CSV file here, or click to upload"}
                </p>
                <input
                  type="file"
                  id="fileInput"
                  accept=".csv"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </div>
              {errors.file && <p style={errorMessageStyle}>{errors.file}</p>}
            </div>

            <div style={instructionsBoxStyle}>
              <h3 style={instructionsTitleStyle}>Instructions</h3>
              <p style={instructionsTextStyle}>
                Upload a CSV file containing product EAN/GTIN in the specified format.
              </p>
              <p style={instructionsTextStyle}>
                Each row in the CSV file should represent a single product to be scraped.
              </p>
              <p style={instructionsTextStyle}>
                The CSV file can be downloaded as a template to ensure correct formatting.
              </p>
            </div>
          </div>

          <div style={actionsStyle}>
            <button onClick={downloadSampleCSV} type="button" style={downloadButtonStyle}>
              <Download size={16} />
              Download sample CSV file
            </button>
            <button onClick={handleSubmit} style={nextButtonStyle} disabled={isLoading}>
              {isLoading ? "Processing..." : "Next"}
              {!isLoading && <ArrowRight size={16} />}
            </button>
          </div>

          {errors.api && <p style={{ ...errorMessageStyle, textAlign: "center", marginTop: "16px" }}>{errors.api}</p>}
        </div>
      </div>
    </div>
  )
}

export default AddFile

