import React, { useState } from 'react';
import MenuBar from '../components/MenuBar'; // Import Sidebar component
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation

const About = () => {

    const pageData = {
        name: "UHRIG",
        email: 'contact@uhrig.com',
        address: "New York",
        phone : "+1234567890",
        site : "uhrig.com",
        country : "United Kingdom",
      };
  return (
    <div className="site-page-wrapper">
      <div className="site-page-wrapper-inner">
        <div className="content-section">
            <MenuBar />
            <div className="custom-container">
                <div class="about-us-content">
                    <div className="about-us-content-card">
                        <div className="about-us-content-card-grid">
                            <div className="about-us-content-text">
                                <div className="heading">About Us</div>
                                <div className="content">
                                    We are UHRIG Solutions, a technology company dedicated to providing innovative solutions for e-commerce data extraction. Our flagship service, "Search," is a powerful scraping tool designed to help businesses and individuals efficiently gather product data from popular online marketplaces like eBay and Amazon.
                                </div>
                            </div>
                            <div className="about-us-content-image">
                                <img src="/images/about.jpg" alt="" />
                            </div>
                        </div>
                        <div className="about-us-content-card-grid" style={{marginTop:'50px'}}>
                            <div className="about-us-content-image">
                                <img src="/images/vission.jpg" alt="" />
                            </div>
                            <div className="about-us-content-text">
                                <div className="heading">Our Vision</div>
                                <div className="content">
                                Our vision is to empower users with the data they need to make informed decisions and gain a competitive edge in the online marketplace. We strive to simplify the process of data acquisition, enabling users to focus on analysis, strategy, and growth.
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    {/* <h1 style={{textAlign:'center'}}>About Us</h1>
                    <p style={{textAlign:'center'}}>
                        We are UHRIG Solutions, a technology company dedicated to providing innovative solutions for e-commerce data extraction. Our flagship service, "Search," is a powerful scraping tool designed to help businesses and individuals efficiently gather product data from popular online marketplaces like eBay and Amazon.
                    </p>
                    <h1 style={{textAlign:'center'}}>Our Vision</h1>
                    <p style={{textAlign:'center'}}>
                        Our vision is to empower users with the data they need to make informed decisions and gain a competitive edge in the online marketplace. We strive to simplify the process of data acquisition, enabling users to focus on analysis, strategy, and growth.
                    </p> */}
                </div>
            </div>
        </div>
        <div className="footer">
            <div className="custom-container">
                <div className="footer-main">
                    <div className="footer-logo">
                        <div className="footer-logo-image">
                            <img src="/images/logo.png" alt="" />
                        </div>
                        <div className="footer-bottom-text">
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aspernatur corrupti, voluptas facere eos, neque provident in aut, repellat architecto doloremque ipsa. Dolorum reprehenderit totam vero adipisci quos sapiente nostrum placeat!
                        </div>
                        <div className="footer-docs">
                            <a href="/privacy-policy" className="link">Privacy Policy</a>
                            <a href="/terms-of-service" className="link">Terms & Service</a>
                        </div>
                    </div>
                    <div className="footer-contact-us">
                        <div className="footer-heading">Reach Us</div>
                        <a href="mailto:sales@uhrig.com" className="link">sales@uhrig.com</a>
                    </div>
                </div>
                <div className="copyright">Copyright © 2025 urigh.com - All Rights Reserved.</div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default About;