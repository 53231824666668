

import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { SearchIcon, Download, ChevronLeft, ChevronRight, LogOut, Check, Users, Briefcase, UserX } from "lucide-react"

import config from '../../lib/config'

const Admin = () => {
  // View state (jobs or users)
  const [activeView, setActiveView] = useState("jobs") // "jobs" or "users"

  // Jobs state
  const [jobs, setJobs] = useState([])
  const [jobsLoading, setJobsLoading] = useState(false)
  const [jobsError, setJobsError] = useState(null)

  // Users state
  const [users, setUsers] = useState([])
  const [usersLoading, setUsersLoading] = useState(false)
  const [usersError, setUsersError] = useState(null)

  const navigate = useNavigate()

  // Pagination state for jobs
  const [currentJobsPage, setCurrentJobsPage] = useState(1)
  const [totalJobsPages, setTotalJobsPages] = useState(1)
  const [totalJobs, setTotalJobs] = useState(0)
  const [jobsLimit, setJobsLimit] = useState(10)

  // Search state for jobs
  const [jobsSearchQuery, setJobsSearchQuery] = useState("")

  // Handle search input change for jobs
  const handleJobsSearchChange = (e) => {
    setJobsSearchQuery(e.target.value)
  }

  // Handle logout
  const handleLogout = () => {
    localStorage.clear()
    navigate("/")
  }

  const handleJobsSearchSubmit = (e) => {
    e.preventDefault()
    setCurrentJobsPage(1) // Reset to first page when searching
    fetchJobs(1)
  }

  const fetchJobs = async (page = 1) => {
    setJobsLoading(true)
    setJobsError(null)

    const token = localStorage.getItem("access")
    if (!token) {
      setJobsError("Authentication token is missing")
      setJobsLoading(false)
      navigate("/login")
      return
    }

    try {
      // Build query parameters
      let url = `${config.BASE_URL}/api/jobs/getJobsListByAdmin/?page=${page}&limit=${jobsLimit}`
      if (jobsSearchQuery) {
        url += `&jobId=${jobsSearchQuery}`
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if(response.status === 401){
        navigate('/')
      }


      

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }



      const data = await response.json()

      console.log(data)

      // Update to match the Django API response structure
      setJobs(data.data || data.jobs || [])
      setTotalJobs(data.totalJobs || 0)
      setCurrentJobsPage(data.currentPage || 1)
      setTotalJobsPages(data.totalPages || 1)
      setJobsLimit(data.currentLimit || 10)
    } catch (error) {
      console.log("Error fetching jobs:", error)
      setJobsError("Failed to fetch jobs. Please try again.")
    } finally {
      setJobsLoading(false)
    }
  }

  // Fetch users
  const fetchUsers = async () => {
    setUsersLoading(true)
    setUsersError(null)

    const token = localStorage.getItem("access")
    if (!token) {
      setUsersError("Authentication token is missing")
      setUsersLoading(false)
      navigate("/login")
      return
    }

    try {
      const response = await fetch(`${config.BASE_URL}/user/GetAllUsersView/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if(response.status === 401){
        navigate('/')
      }

      

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      

      
      const data = await response.json()
      setUsers(data || [])
    } catch (error) {
      console.log("Error fetching users:", error)
      setUsersError("Failed to fetch users. Please try again.")
    } finally {
      setUsersLoading(false)
    }
  }

  // Toggle user verification status
  const toggleUserVerification = async (userId) => {
    const token = localStorage.getItem("access")
    if (!token) {
      setUsersError("Authentication token is missing")
      navigate("/login")
      return
    }

    try {
      const response = await fetch(`${config.BASE_URL}/user/ToggleUserAdminVerifiedStatusView/?userId=${userId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      
      if(response.status === 401){
        navigate('/')
      }

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      // Refresh the users list after toggling
      fetchUsers()
    } catch (error) {
      console.log("Error toggling user verification:", error)
      setUsersError("Failed to update user status. Please try again.")
    }
  }

  // Handle page change for jobs
  const handleJobsPageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalJobsPages) {
      setCurrentJobsPage(newPage)
    }
  }

  // Fetch data when view changes
  useEffect(() => {
    if (activeView === "jobs") {
      fetchJobs(currentJobsPage)
    } else if (activeView === "users") {
      fetchUsers()
    }
  }, [activeView])

  // Fetch jobs when page changes
  useEffect(() => {
    if (activeView === "jobs") {
      fetchJobs(currentJobsPage)
    }
  }, [currentJobsPage])

  // Styles
  const containerStyle = {
    padding: "20px",
    color: "#fff",
    maxWidth: "1200px",
    margin: "0 auto",
  }

  const headerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    flexWrap: "wrap",
    gap: "16px",
  }

  const titleStyle = {
    fontSize: "24px",
    fontWeight: "bold",
  }

  const logoutButtonStyle = {
    backgroundColor: "#E84118",
    color: "white",
    border: "none",
    borderRadius: "4px",
    padding: "10px 20px",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  }

  const cardStyle = {
    backgroundColor: "#121212",
    borderRadius: "8px",
    padding: "20px",
    marginBottom: "20px",
  }

  const searchBarContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "16px",
  }

  const searchInputContainerStyle = {
    display: "flex",
    flex: "1",
    minWidth: "250px",
  }

  const searchInputStyle = {
    flex: "1",
    padding: "10px 16px",
    backgroundColor: "#1E1E1E",
    border: "1px solid #333",
    borderRadius: "4px 0 0 4px",
    color: "#fff",
    fontSize: "14px",
  }

  const searchButtonStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 16px",
    backgroundColor: "#333",
    border: "none",
    borderRadius: "0 4px 4px 0",
    color: "white",
    cursor: "pointer",
  }

  const tableContainerStyle = {
    overflowX: "auto",
  }

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "16px",
  }

  const thStyle = {
    textAlign: "left",
    padding: "12px 16px",
    borderBottom: "1px solid #333",
    color: "#999",
    fontSize: "14px",
  }

  const tdStyle = {
    padding: "12px 16px",
    borderBottom: "1px solid #222",
    fontSize: "14px",
  }

  const downloadLinkStyle = {
    color: "#4D7CFE",
    textDecoration: "none",
  }

  const paginationStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
    flexWrap: "wrap",
    gap: "16px",
  }

  const paginationInfoStyle = {
    color: "#999",
    fontSize: "14px",
  }

  const paginationControlsStyle = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  }

  const pageButtonStyle = (isActive) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "36px",
    height: "36px",
    backgroundColor: isActive ? "#E84118" : "#333",
    border: "none",
    borderRadius: "4px",
    color: "white",
    cursor: isActive ? "default" : "pointer",
    fontSize: "14px",
  })

  const navButtonStyle = (disabled) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "36px",
    height: "36px",
    backgroundColor: "#333",
    border: "none",
    borderRadius: "4px",
    color: "white",
    cursor: disabled ? "not-allowed" : "pointer",
    opacity: disabled ? 0.5 : 1,
  })

  const loadingStyle = {
    textAlign: "center",
    padding: "20px",
    color: "#999",
  }

  const errorStyle = {
    textAlign: "center",
    padding: "20px",
    color: "#FF4D4F",
  }

  const emptyStateStyle = {
    textAlign: "center",
    padding: "40px 20px",
    color: "#999",
  }

  const toggleContainerStyle = {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
    gap: "8px",
  }

  const toggleButtonStyle = (isActive) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    padding: "10px 20px",
    backgroundColor: isActive ? "#E84118" : "#333",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: isActive ? "default" : "pointer",
    fontSize: "14px",
    fontWeight: "bold",
  })

  const actionButtonStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "6px",
    backgroundColor: "#333",
    border: "none",
    borderRadius: "4px",
    color: "white",
    cursor: "pointer",
  }

  // Generate page numbers for pagination
  const getPageNumbers = () => {
    const pageNumbers = []
    const maxVisiblePages = 5

    if (totalJobsPages <= maxVisiblePages) {
      // Show all pages if total pages are less than max visible
      for (let i = 1; i <= totalJobsPages; i++) {
        pageNumbers.push(i)
      }
    } else {
      // Show a subset of pages with current page in the middle if possible
      let startPage = Math.max(1, currentJobsPage - Math.floor(maxVisiblePages / 2))
      const endPage = Math.min(totalJobsPages, startPage + maxVisiblePages - 1)

      // Adjust if we're near the end
      if (endPage - startPage + 1 < maxVisiblePages) {
        startPage = Math.max(1, endPage - maxVisiblePages + 1)
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i)
      }
    }

    return pageNumbers
  }

  function downloadFile(url) {
    fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = "Log.txt";
      document.body.appendChild(a);
      a.click();
      a.remove();
    })
    .catch(error => console.error("Download failed:", error));
  }

  return (
    <div style={containerStyle}>
      <div style={headerStyle}>
        <h1 style={titleStyle}>Admin Dashboard</h1>
        <button onClick={handleLogout} style={logoutButtonStyle}>
          <LogOut size={16} />
          Logout
        </button>
      </div>

      <div style={toggleContainerStyle}>
        <button
          style={toggleButtonStyle(activeView === "jobs")}
          onClick={() => setActiveView("jobs")}
          disabled={activeView === "jobs"}
        >
          <Briefcase size={16} />
          Jobs
        </button>
        <button
          style={toggleButtonStyle(activeView === "users")}
          onClick={() => setActiveView("users")}
          disabled={activeView === "users"}
        >
          <Users size={16} />
          Users
        </button>
      </div>

      {activeView === "jobs" ? (
        <>
          <div style={cardStyle}>
            <div style={searchBarContainerStyle}>
              <form onSubmit={handleJobsSearchSubmit} style={searchInputContainerStyle}>
                <input
                  type="text"
                  placeholder="Search by Job ID..."
                  style={searchInputStyle}
                  value={jobsSearchQuery}
                  onChange={handleJobsSearchChange}
                />
                <button type="submit" style={searchButtonStyle}>
                  <SearchIcon size={18} />
                </button>
              </form>
            </div>
          </div>

          <div style={cardStyle}>
            <div
              style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "16px" }}
            >
              <h2 style={{ fontSize: "18px", fontWeight: "bold" }}>All Jobs</h2>
            </div>

            {jobsLoading ? (
              <div style={loadingStyle}>Loading jobs...</div>
            ) : jobsError ? (
              <div style={errorStyle}>{jobsError}</div>
            ) : jobs.length === 0 ? (
              <div style={emptyStateStyle}>No jobs found. Try adjusting your search criteria.</div>
            ) : (
              <>
                <div style={tableContainerStyle}>
                  <table style={tableStyle}>
                    <thead>
                      <tr>
                        <th style={thStyle}>Job ID</th>
                        <th style={thStyle}>Name</th>
                        <th style={thStyle}>Date</th>
                        <th style={thStyle}>Records (Total/Skipped)</th>
                        <th style={thStyle}>Coin Usage</th>
                        <th style={thStyle}>Execution Time</th>
                        <th style={thStyle}>Status</th>
                        <th style={thStyle}>Amazon</th>
                        <th style={thStyle}>Ebay</th>
                        <th style={thStyle}>Log</th>
                      </tr>
                    </thead>
                    <tbody>
                      {jobs.map((job, index) => (
                        <tr key={job.job_id || index}>
                          <td style={tdStyle}>{job.job_id}</td>
                          <td style={tdStyle}>{job.name || "N/A"}</td>
                          <td style={tdStyle}>{job.created_at?.slice(0, 10) || "N/A"}</td>
                          <td style={tdStyle}>
                            {job.total_rows || 0} / {job.skipped_rows || 0}
                          </td>
                          <td style={tdStyle}>{job.coins_used || 0} coins</td>
                          <td style={tdStyle}>{job.execution_time || 0}s</td>
                          <td style={tdStyle}>{job.status || "Unknown"}</td>
                          <td style={tdStyle}>
                            {job.status === "Completed" && job.output_files && job.output_files[0] ? (
                              <a href={job.output_files[0]} target="_blank" style={downloadLinkStyle} rel="noreferrer">
                                <Download style={{ color: "red" }} />
                              </a>
                            ) : (
                              "N/A"
                            )}
                          </td>
                          <td style={tdStyle}>
                            {job.status === "Completed" && job.output_files && job.output_files[1] ? (
                              <a href={job.output_files[1]} target="_blank" style={downloadLinkStyle} rel="noreferrer">
                                <Download style={{ color: "red" }} />
                              </a>
                            ) : (
                              "N/A"
                            )}
                          </td>
                          <td style={tdStyle}>
                            {job.status === "Completed" && job.output_files && job.output_files[2] ? (
                              <span onClick={()=>downloadFile(job.output_files[2])}  target="_blank" style={downloadLinkStyle} rel="noreferrer">
                                <Download style={{ color: "red" }} />
                              </span>
                            ) : (
                              "N/A"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div style={paginationStyle}>
                  <div style={paginationInfoStyle}>
                    Showing {Math.min(jobs.length, jobsLimit)} of {totalJobs} jobs
                  </div>

                  <div style={paginationControlsStyle}>
                    <button
                      style={navButtonStyle(currentJobsPage === 1)}
                      onClick={() => handleJobsPageChange(currentJobsPage - 1)}
                      disabled={currentJobsPage === 1}
                    >
                      <ChevronLeft size={18} />
                    </button>

                    {getPageNumbers().map((pageNum) => (
                      <button
                        key={pageNum}
                        style={pageButtonStyle(pageNum === currentJobsPage)}
                        onClick={() => handleJobsPageChange(pageNum)}
                        disabled={pageNum === currentJobsPage}
                      >
                        {pageNum}
                      </button>
                    ))}

                    <button
                      style={navButtonStyle(currentJobsPage === totalJobsPages)}
                      onClick={() => handleJobsPageChange(currentJobsPage + 1)}
                      disabled={currentJobsPage === totalJobsPages}
                    >
                      <ChevronRight size={18} />
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <div style={cardStyle}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "16px" }}>
            <h2 style={{ fontSize: "18px", fontWeight: "bold" }}>Users</h2>
          </div>

          {usersLoading ? (
            <div style={loadingStyle}>Loading users...</div>
          ) : usersError ? (
            <div style={errorStyle}>{usersError}</div>
          ) : users.length === 0 ? (
            <div style={emptyStateStyle}>No unverified users found.</div>
          ) : (
            <div style={tableContainerStyle}>
              <table style={tableStyle}>
                <thead>
                  <tr>
                    <th style={thStyle}>ID</th>
                    <th style={thStyle}>Username</th>
                    <th style={thStyle}>Email</th>
                    <th style={thStyle}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user) => (
                    <tr key={user.id}>
                      <td style={tdStyle}>{user.id}</td>
                      <td style={tdStyle}>{user.username}</td>
                      <td style={tdStyle}>{user.email}</td>
                      {
                        user.is_admin_verified ?
                      <td style={tdStyle}>
                        <button
                          style={{ ...actionButtonStyle, backgroundColor: "#4CAF50", cursor:"not-allowed" }}
                          title="Verify User"
                        >
                          <Check size={16} />
                        </button>
                      </td>
                      :
                      <td style={tdStyle}>
                        <button
                          onClick={() => toggleUserVerification(user.id)}
                          style={{ ...actionButtonStyle, backgroundColor: "red" }}
                          title="Verify User"
                        >
                          <UserX size={16} />
                        </button>
                      </td>
                      }

                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Admin

