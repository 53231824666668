import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import config from '../../lib/config'
const AccountCreated = () => {
  const { uidb64, token } = useParams(); // Get parameters from the URL
  const [status, setStatus] = useState("loading"); // "loading", "success", "error"
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await fetch(`${config.BASE_URL}/user/verify-email/${uidb64}/${token}/`);
        const data = await response.json();

        if (response.ok) {
          setStatus("success");
        } else {
          setStatus("error");
        }
      } catch (error) {
        console.error("Verification failed:", error);
        setStatus("error");
      }
    };

    verifyEmail();
  }, [uidb64, token]);

  return (
    <div className="page-wrapper">
      <div className="form-container">
        {/* Logo */}
        <div className="logo-wrapper">
          <img src={logo} alt="Logo" className="logo" />
        </div>

        {/* Show Messages Based on Status */}
        {status === "loading" && <h2>Verifying your account...</h2>}
        {status === "success" && (
          <>
            <h2>Account Verified!</h2>
            <p className="alert-text">Your email has been successfully verified.</p>
            <div style={{ marginBottom: "20px", display: "flex", justifyContent: "center" }}>
              <button onClick={() => navigate("/login")} className="submit-btn">
                Login
              </button>
            </div>
          </>
        )}
        {status === "error" && (
          <>
            <h2>Verification Failed</h2>
            <p className="alert-text">Your account could not be verified.</p>
          </>
        )}
      </div>
    </div>
  );
};

export default AccountCreated;
