import React from 'react';
import '../../assets/css/style.css'; // Import the stylesheet
import logo from '../../assets/images/logo.png'; // Import the logo
import { Link } from 'react-router-dom';

const PasswordResetVerification = () => {
  return (
    <div className="page-wrapper">
      <div className="form-container">
        {/* Logo */}
        <div className="logo-wrapper">
          <img src={logo} alt="Logo" className="logo" />
        </div>
        <h2>Password Reset Verification</h2>
        <p className='alert-text'>
          A password reset verification link has been sent to your email. Please check your inbox to reset your password.
        </p>
        {/* Submit Button */}
        <div style={{ marginBottom: "20px", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
          <Link to={'/login'} className="submit-btn">
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetVerification;
