import { useState, useEffect } from "react"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"

const Contact = () => {
  // State to track window dimensions
  const [windowDimensions, setWindowDimensions] = useState({
    width: typeof window !== "undefined" ? window.innerWidth : 1200,
    height: typeof window !== "undefined" ? window.innerHeight : 800,
  })

  // Form state
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  })

  // Update dimensions on window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault()
    // Here you would typically send the form data to your backend
    console.log("Form submitted:", formData)
    alert("Thank you for your message! We'll get back to you soon.")
    // Reset form
    setFormData({
      name: "",
      email: "",
      subject: "",
      message: "",
    })
  }

  // Determine if mobile view
  const isMobile = windowDimensions.width < 768
  const isTablet = windowDimensions.width >= 768 && windowDimensions.width < 1024

  // All styles in a single object
  const styles = {
    // Page container
    pageContainer: {
      width: "100%",
      minHeight: "100vh",
      margin: 0,
      padding: 0,
      fontFamily: "Arial, sans-serif",
      overflowX: "hidden",
      backgroundColor: "#fff",
      color: "#333",
    },

    // Menu Bar
    menuBar: {
      backgroundColor: "#070808",
      padding: "15px 0",
      width: "100%",
    },
    menuContainer: {
      width: "100%",
      maxWidth: "1200px",
      margin: "0 auto",
      padding: "0 20px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    logo: {
      height: "30px",
    },
    buttonsContainer: {
      display: "flex",
      gap: "10px",
    },
    signInButton: {
      padding: "10px 20px",
      backgroundColor: "transparent",
      color: "#ff0000",
      border: "1px solid #ff0000",
      borderRadius: "4px",
      cursor: "pointer",
      fontWeight: "bold",
      transition: "background-color 0.3s ease",
    },
    signUpButton: {
      padding: "10px 20px",
      backgroundColor: "#ff0000",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      fontWeight: "bold",
      transition: "background-color 0.3s ease",
    },

    // Hero Section
    heroSection: {
      backgroundColor: "#070808",
      color: "#fff",
      padding: isMobile ? "40px 0" : "60px 0",
      position: "relative",
    },
    heroContainer: {
      width: "100%",
      maxWidth: "1200px",
      margin: "0 auto",
      padding: "0 20px",
    },
    heroContent: {
      textAlign: "center",
      maxWidth: "800px",
      margin: "0 auto",
    },
    heroHeading: {
      fontSize: isMobile ? "28px" : isTablet ? "36px" : "42px",
      fontWeight: "bold",
      marginBottom: "20px",
      lineHeight: 1.2,
    },
    heroSubheading: {
      fontSize: isMobile ? "16px" : "18px",
      lineHeight: 1.6,
      color: "#ccc",
      marginBottom: "40px",
    },

    // Divider
    divider: {
      width: "100%",
      height: "auto",
      display: "block",
    },

    // Contact Section
    contactSection: {
      backgroundColor: "#1A1A1B",
      color: "#fff",
      padding: isMobile ? "40px 0" : "80px 0",
    },
    contactContainer: {
      width: "100%",
      maxWidth: "1200px",
      margin: "0 auto",
      padding: "0 20px",
    },
    contactContent: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      gap: "40px",
      justifyContent: "space-between",
    },
    contactFormContainer: {
      flex: "1",
      maxWidth: isMobile ? "100%" : "60%",
    },
    contactInfoContainer: {
      flex: "1",
      maxWidth: isMobile ? "100%" : "35%",
    },
    sectionHeading: {
      fontSize: isMobile ? "24px" : "30px",
      fontWeight: "bold",
      marginBottom: "20px",
    },
    formGroup: {
      marginBottom: "20px",
    },
    label: {
      display: "block",
      marginBottom: "8px",
      fontSize: "16px",
      color: "#ff0000",
      fontWeight: "bold",
    },
    input: {
      width: "100%",
      padding: "12px 15px",
      backgroundColor: "#2A2A2B",
      border: "1px solid #333",
      borderRadius: "4px",
      color: "#fff",
      fontSize: "16px",
    },
    textarea: {
      width: "100%",
      padding: "12px 15px",
      backgroundColor: "#2A2A2B",
      border: "1px solid #333",
      borderRadius: "4px",
      color: "#fff",
      fontSize: "16px",
      minHeight: "150px",
      resize: "vertical",
    },
    submitButton: {
      padding: "12px 24px",
      backgroundColor: "#ff0000",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      fontWeight: "bold",
      fontSize: "16px",
      transition: "background-color 0.3s ease",
    },
    contactInfoItem: {
      marginBottom: "30px",
    },
    contactInfoTitle: {
      fontSize: "18px",
      fontWeight: "bold",
      marginBottom: "10px",
    },
    contactInfoText: {
      fontSize: "16px",
      lineHeight: 1.6,
      color: "#ccc",
    },
    contactInfoLink: {
      color: "#ff0000",
      textDecoration: "none",
      transition: "color 0.3s ease",
    },
    socialLinks: {
      display: "flex",
      gap: "15px",
      marginTop: "20px",
    },
    socialIcon: {
      width: "40px",
      height: "40px",
      backgroundColor: "#2A2A2B",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#fff",
      textDecoration: "none",
      transition: "background-color 0.3s ease",
    },
    mapContainer: {
      marginTop: "30px",
      height: "250px",
      backgroundColor: "#2A2A2B",
      borderRadius: "8px",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#ccc",
    },

    // Footer
    footer: {
      backgroundColor: "#070808",
      color: "#ccc",
      padding: isMobile ? "40px 0 20px" : "60px 0 20px",
    },
    footerContainer: {
      width: "100%",
      maxWidth: "1200px",
      margin: "0 auto",
      padding: "0 20px",
    },
    footerContent: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      flexWrap: "wrap",
      gap: isMobile ? "30px" : "40px",
      justifyContent: "space-between",
    },
    footerLeft: {
      flex: isMobile ? "1 1 100%" : "1 1 60%",
      maxWidth: isMobile ? "100%" : "600px",
    },
    footerLogo: {
      maxWidth: "150px",
      marginBottom: "20px",
    },
    footerText: {
      marginBottom: "20px",
      lineHeight: 1.6,
      fontSize: "14px",
    },
    footerLinks: {
      display: "flex",
      gap: "20px",
      marginBottom: "30px",
    },
    footerLink: {
      color: "#ff0000",
      textDecoration: "none",
      transition: "color 0.3s ease",
    },
    footerRight: {
      flex: isMobile ? "1 1 100%" : "0 1 30%",
    },
    footerHeading: {
      fontSize: "18px",
      marginBottom: "15px",
      fontWeight: "bold",
    },
    copyright: {
      borderTop: "1px solid #333",
      marginTop: "40px",
      paddingTop: "20px",
      textAlign: "center",
      fontSize: "14px",
    },
  }

  return (
    <div style={styles.pageContainer}>
      {/* Menu Bar */}
      {/* <div style={styles.menuBar}>
        <div style={styles.menuContainer}>
          <a href="/">
            <img src="/images/logo.png" alt="Logo" style={styles.logo} />
          </a>

          <div style={styles.buttonsContainer}>
            <button style={styles.signInButton}>Sign In</button>
            <button style={styles.signUpButton}>Sign Up</button>
          </div>
        </div>
      </div> */}

      <Navbar/>

      {/* Hero Section */}
      <div style={styles.heroSection}>
        <div style={styles.heroContainer}>
          <div style={styles.heroContent}>
            <h1 style={styles.heroHeading}>Get in Touch</h1>
            <p style={styles.heroSubheading}>
              Have questions about our data extraction services? We're here to help. Reach out to our team and we'll get back to you as soon as possible.
            </p>
          </div>
        </div>
      </div>

      {/* Divider */}
      <svg width="100%" style={styles.divider} viewBox="0 0 1439 248" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M783 116.5L0 248V0.00012207L1439 0.00012207L783 116.5Z" fill="#070808" />
      </svg>

      {/* Contact Section */}
      <div style={styles.contactSection}>
        <div style={styles.contactContainer}>
          <div style={styles.contactContent}>
            {/* Contact Form */}
            <div style={styles.contactFormContainer}>
              <h2 style={styles.sectionHeading}>Send Us a Message</h2>
              <form onSubmit={handleSubmit}>
                <div style={styles.formGroup}>
                  <label style={styles.label} htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    style={styles.input}
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div style={styles.formGroup}>
                  <label style={styles.label} htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    style={styles.input}
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div style={styles.formGroup}>
                  <label style={styles.label} htmlFor="subject">Subject</label>
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    style={styles.input}
                    value={formData.subject}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div style={styles.formGroup}>
                  <label style={styles.label} htmlFor="message">Message</label>
                  <textarea
                    id="message"
                    name="message"
                    style={styles.textarea}
                    value={formData.message}
                    onChange={handleInputChange}
                    required
                  ></textarea>
                </div>
                <button type="submit" style={styles.submitButton}>Send Message</button>
              </form>
            </div>

            {/* Contact Information */}
            <div style={styles.contactInfoContainer}>
              <h2 style={styles.sectionHeading}>Contact Information</h2>
              
              <div style={styles.contactInfoItem}>
                <h3 style={styles.contactInfoTitle}>Email</h3>
                <p style={styles.contactInfoText}>
                  <a href="mailto:sales@uhrig.com" style={styles.contactInfoLink}>sales@uhrig.com</a>
                </p>
              </div>
              
              <div style={styles.contactInfoItem}>
                <h3 style={styles.contactInfoTitle}>Phone</h3>
                <p style={styles.contactInfoText}>+1 (555) 123-4567</p>
              </div>
              
              <div style={styles.contactInfoItem}>
                <h3 style={styles.contactInfoTitle}>Address</h3>
                <p style={styles.contactInfoText}>
                  123 Data Street<br />
                  Tech City, TC 98765<br />
                  United States
                </p>
              </div>
              
              <div style={styles.contactInfoItem}>
                <h3 style={styles.contactInfoTitle}>Follow Us</h3>
                <div style={styles.socialLinks}>
                  <a href="#" style={styles.socialIcon} aria-label="Twitter">
                    <span>𝕏</span>
                  </a>
                  <a href="#" style={styles.socialIcon} aria-label="Facebook">
                    <span>f</span>
                  </a>
                  <a href="#" style={styles.socialIcon} aria-label="LinkedIn">
                    <span>in</span>
                  </a>
                  <a href="#" style={styles.socialIcon} aria-label="Instagram">
                    <span>📷</span>
                  </a>
                </div>
              </div>
              
              

            </div>
          </div>
        </div>
      </div>

      {/* Divider */}
      <svg width="100%" style={styles.divider} viewBox="0 0 1439 248" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M783 116.5L0 248V0.00012207L1439 0.00012207L783 116.5Z" fill="#1A1A1B" />
      </svg>

      {/* Footer */}
      <Footer/>
    </div>
  )
}

export default Contact
