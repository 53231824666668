import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { CreditCard, ArrowLeft } from 'lucide-react';
import { Link } from "react-router-dom";
import config from '../../../lib/config'

const StripeFeeCollection = ({ type }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!stripe || !elements) {
      setError("Stripe has not loaded yet. Please try again.");
      return;
    }

    setLoading(true);
    setError(null);
    
    try {
      const card = elements.getElement(CardElement);
      
      // Create PaymentMethod
      const { paymentMethod, error: stripeError } = await stripe.createPaymentMethod({
        type: "card",
        card: card,
      });
      
      if (stripeError) {
        setError(stripeError.message);
        return;
      }
      
      // Send PaymentMethod ID to Backend
      const stripeResponse = await stripePaymentHandler(paymentMethod.id);
      
      if (stripeResponse.success) {
        setSuccess(true);
        setTimeout(() => {
          if (type === "purchase") {
            navigate('/dashboard');
          } else {
            navigate('/wallet/payment-method');
          }
        }, 1500);
      } else {
        setError(stripeResponse.error || "An error occurred. Please try again.");
      }
    } catch (e) {
      setError("An unexpected error occurred. Please try again.");
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
    
  async function stripePaymentHandler(paymentMethodId) {
    const token = localStorage.getItem("access");

    if (!token) {
      setError("Authentication token is missing");
      return { success: false, error: "Authentication token is missing" };
    }

    const paymentData = { 
      payment_method_id: paymentMethodId,
    };
    
    try {
      const response = await fetch(
        type === "purchase" 
          ? `${config.BASE_URL}/user/ChargeCustomerOneTime/`
          : `${config.BASE_URL}/wallet/add_card/`,
        {
          method: "POST",
          headers: { 
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(paymentData),
        }
      );

      if(response.status === 401){
        navigate('/')
      }

      if (!response.ok) {
        const errorData = await response.json();
        return { success: false, error: errorData.error || "Failed to process payment" };
      }

      return await response.json();
    } catch (error) {
      return { success: false, error: "Network error. Please try again." };
    }
  }

  // Styles
  const pageWrapperStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
  };

  const containerStyle = {
    width: '100%',
    maxWidth: '480px',
  };

  const cardStyle = {
    backgroundColor: '#121212',
    borderRadius: '8px',
    padding: '32px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
  };

  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px',
  };

  const titleStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '0',
    color: '#fff',
  };

  const backLinkStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: '#999',
    textDecoration: 'none',
    fontSize: '14px',
  };

  const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  };

  const cardElementContainerStyle = {
    padding: '16px',
    backgroundColor: '#1E1E1E',
    borderRadius: '4px',
    border: error ? '1px solid #FF4D4F' : '1px solid #333',
  };

  const buttonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    padding: '14px 24px',
    backgroundColor: '#E84118',
    border: 'none',
    borderRadius: '4px',
    color: 'white',
    cursor: loading ? 'not-allowed' : 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    opacity: loading ? 0.7 : 1,
  };

  const errorMessageStyle = {
    color: '#FF4D4F',
    fontSize: '14px',
    marginTop: '8px',
    padding: '8px',
    backgroundColor: 'rgba(255, 77, 79, 0.1)',
    borderRadius: '4px',
    display: error ? 'block' : 'none',
  };

  const successMessageStyle = {
    color: '#52C41A',
    fontSize: '14px',
    marginTop: '8px',
    padding: '8px',
    backgroundColor: 'rgba(82, 196, 26, 0.1)',
    borderRadius: '4px',
    display: success ? 'block' : 'none',
  };

  const cardIconStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '24px',
  };

  const cardElementOptions = {
    style: {
      base: {
        color: "white",
        fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: "rgba(255, 255, 255, 0.5)",
        },
        iconColor: "#E84118",
      },
      invalid: {
        color: "#FF4D4F",
        iconColor: "#FF4D4F",
      },
    },
  };

  return (
    <div style={pageWrapperStyle}>
      <div style={containerStyle}>
        <div style={cardStyle}>
          <div style={headerStyle}>
            <h2 style={titleStyle}>
              {type === "purchase" ? "Subscription Fee" : "Add New Card"}
            </h2>
            <Link to={type === "purchase" ? "/dashboard" : "/wallet/payment-method"} style={backLinkStyle}>
              <ArrowLeft size={16} />
              Back
            </Link>
          </div>
          
          <div style={cardIconStyle}>
            <CreditCard size={48} color="#E84118" />
          </div>
          
          <form onSubmit={handleSubmit} style={formStyle}>
            <div>
              <div style={cardElementContainerStyle}>
                <CardElement options={cardElementOptions} />
              </div>
              <div style={errorMessageStyle}>{error}</div>
              <div style={successMessageStyle}>
                {success ? "Card added successfully! Redirecting..." : ""}
              </div>
            </div>
            
            {
              !success &&
              <button 
              disabled={!stripe || loading} 
              type="submit" 
              style={buttonStyle}
              >
              {loading ? "Processing..." : type === "purchase" ? "Pay Now" : "Add Card"}
            </button>
            }
          </form>
        </div>
      </div>
    </div>
  );
};

export default StripeFeeCollection;
