import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsService from './pages/TermsService';
import About from './pages/About';
import ContactUs from './pages/ContactUs';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import AccountCreated from './pages/auth/AccountCreated';
import EmailVerification from './pages/auth/EmailVerification';
import SubscriptionFee from './pages/auth/SubscriptionFee';
import AccountRecovery from './pages/auth/AccountRecovery';
import RecoveryEmailSent from './pages/auth/RecoveryEmailSent';
import ResetPassword from './pages/auth/ResetPassword';
import DashboardLayout from './pages/dashboard/DashboardLayout';
import Dashboard from './pages/dashboard/Dashboard';
import Settings from './pages/dashboard/Settings';
import Profile from './pages/dashboard/Profile';
import Search from './pages/dashboard/Search';
import AddFile from './pages/dashboard/AddFile';
import FileSummary from './pages/dashboard/FileSummary';
import Wallet from './pages/dashboard/Wallet';
import AddBalance from './pages/dashboard/AddBalance';
import PaymentMethod from './pages/dashboard/PaymentMethod';
import AddPaymentMethod from './pages/dashboard/AddPaymentMethod';
import { AppProvider } from './context/AppProvider';
import ForgotPassword  from './pages/auth/forgotPassword'
import PasswordResetVerification  from './pages/auth/ResetVerification'
import Admin from './pages/Admin/Admin';
function App() {
  return (
    <AppProvider>
    <Router>
      <Routes>
        {/* Auth Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/verify-email/:uidb64/:token" element={<AccountCreated />} />
        <Route path="/email-verification" element={<EmailVerification />} />
        <Route path="/subscription-fee" element={<SubscriptionFee />} />
        <Route path="/account-recovery" element={<AccountRecovery />} />
        <Route path="/recovery-email-sent" element={<RecoveryEmailSent />} />
        <Route path="/reset-password/:uidb64/:token" element={<ResetPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/password-reset-verification" element={<PasswordResetVerification />} />
        
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsService />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<ContactUs />} />

        {/* Admin  */}
        <Route path="/admin" element={<Admin />} />

        {/* Dashboard Routes */}
        <Route path="/search" element={<DashboardLayout />}>
          <Route index element={<Search />} />
        </Route>
        <Route path="/add-file" element={<DashboardLayout />}>
          <Route index element={<AddFile />} />
          <Route path="summary" element={<FileSummary />} />
        </Route>
        <Route path="/wallet" element={<DashboardLayout />}>
          <Route index element={<Wallet />} />
          <Route path="add-balance" element={<AddBalance />} />
          <Route path="payment-method" element={<PaymentMethod />} />
          <Route path="add-payment-method" element={<AddPaymentMethod />} />
        </Route>
        <Route path="/setting" element={<DashboardLayout />}>
          <Route index element={<Settings />} />
        </Route>
        <Route path="/dashboard" element={<DashboardLayout />}>
          <Route index element={<Dashboard />} />
          <Route path="profile" element={<Profile />} />
        </Route>
      </Routes>
    </Router>
    </AppProvider>
  );
}

export default App;
