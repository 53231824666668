"use client"

import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Trash2, CreditCard, Plus } from "lucide-react"
import { useNavigate } from "react-router-dom"
import config from '../../lib/config'

const PaymentMethod = () => {
  const [cards, setCards] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [selectedPaymentId, setSelectedPaymentId] = useState("")
  const navigate = useNavigate()
  
  function capitalizeFirstLetter(string) {
    if (!string) return ""
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  useEffect(() => {
    fetchCardsList()
  }, [])

  const fetchCardsList = async () => {
    const token = localStorage.getItem("access")

    if (!token) {
      setError("Authentication token is missing")
      setLoading(false)
      return
    }

    try {
      const response = await fetch(`${config.BASE_URL}/wallet/fetchCardslist/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })

      if(response.status === 401){
        navigate('/')
      }

      if (!response.ok) {
        throw new Error("Failed to fetch cards")
      }

      const data = await response.json()
      setCards(data.data?.data || [])
      setSelectedPaymentId(data.payment_id)
    } catch (err) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  const deleteCard = async (cardId) => {
    const token = localStorage.getItem("access")

    if (!token) {
      alert("Authentication token is missing")
      return
    }

    const confirmDelete = window.confirm("Are you sure you want to delete this card?")
    if (!confirmDelete) return

    try {
      const res = await fetch(`${config.BASE_URL}/wallet/delete_card/?payment_id=${cardId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })

      if(res.status === 401){
        navigate('/')
      }

      if (res.status !== 200) {
        if (res.status === 400) {
          const response = await res.json()
          if (response.error) {
            alert(response.error)
            return
          }
        } else {
          throw new Error("Failed to delete card")
        }
      }

      alert("Card deleted successfully")
      setCards(cards.filter((card) => card.id !== cardId))
    } catch (err) {
      alert(err.message)
    }
  }

  const changeCurrentPaymentMethod = async (cardId) => {
    const token = localStorage.getItem("access")

    if (!token) {
      alert("Authentication token is missing")
      return
    }

    try {
      const response = await fetch(`${config.BASE_URL}/user/setCurrentPaymentMethod/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ payment_id: cardId }),
      })

      if(response.status === 401){
        navigate('/')
      }

      if (!response.ok) {
        throw new Error("Failed to change payment method")
      }

      alert("Payment method updated successfully")
      setSelectedPaymentId(cardId)
    } catch (err) {
      alert(err.message)
    }
  }

  // Styles
  const containerStyle = {
    padding: "20px",
    color: "#fff",
    maxWidth: "1200px",
    margin: "0 auto",
  }

  const titleStyle = {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
  }

  const cardStyle = {
    backgroundColor: "#121212",
    borderRadius: "8px",
    padding: "24px",
    marginTop: "20px",
  }

  const cardRowStyle = {
    padding: "16px 0",
    borderBottom: "1px solid #333",
  }

  const cardGridStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "16px",
  }

  const cardInfoStyle = {
    flex: "1",
    minWidth: "200px",
  }

  const cardTypeStyle = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    marginBottom: "8px",
    fontSize: "16px",
    fontWeight: "bold",
  }

  const cardNumberStyle = {
    display: "block",
    fontSize: "14px",
    color: "#999",
  }

  const actionsContainerStyle = {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  }

  const selectButtonStyle = {
    backgroundColor: "#E84118",
    color: "white",
    padding: "8px 16px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "14px",
  }

  const selectedLabelStyle = {
    fontWeight: "bold",
    color: "#52C41A",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  }

  const deleteButtonStyle = {
    background: "none",
    border: "none",
    cursor: "pointer",
    color: "#FF4D4F",
    display: "flex",
    alignItems: "center",
    padding: "4px",
  }

  const addNewButtonStyle = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "12px 24px",
    backgroundColor: "#E84118",
    border: "none",
    borderRadius: "4px",
    color: "white",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    marginTop: "24px",
  }

  const emptyStateStyle = {
    textAlign: "center",
    padding: "40px 20px",
    color: "#999",
  }

  const loadingStyle = {
    textAlign: "center",
    padding: "40px 20px",
    color: "#999",
  }

  const errorStyle = {
    textAlign: "center",
    padding: "40px 20px",
    color: "#FF4D4F",
  }

  if (loading)
    return (
      <div style={containerStyle}>
        <div style={loadingStyle}>Loading payment methods...</div>
      </div>
    )
  if (error)
    return (
      <div style={containerStyle}>
        <div style={errorStyle}>Error: {error}</div>
      </div>
    )

  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>Payment Method</h1>

      <div style={cardStyle}>
        {cards.length > 0 ? (
          cards.map((card, index) => (
            <div
              style={{
                ...cardRowStyle,
                borderBottom: index === cards.length - 1 ? "none" : "1px solid #333",
              }}
              key={card.id}
            >
              <div style={cardGridStyle}>
                <div style={cardInfoStyle}>
                  <div style={cardTypeStyle}>
                    <CreditCard size={20} />
                    <span>{capitalizeFirstLetter(card.card?.brand)} Card</span>
                  </div>
                  <span style={cardNumberStyle}>**** **** **** {card.card?.last4}</span>
                </div>
                <div style={actionsContainerStyle}>
                  {selectedPaymentId === card.id ? (
                    <span style={selectedLabelStyle}>Selected</span>
                  ) : (
                    <button style={selectButtonStyle} onClick={() => changeCurrentPaymentMethod(card.id)}>
                      Select
                    </button>
                  )}
                  <button style={deleteButtonStyle} onClick={() => deleteCard(card.id)} aria-label="Delete card">
                    <Trash2 size={20} />
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div style={emptyStateStyle}>No saved cards found.</div>
        )}

        <div style={{ marginTop: "24px" }}>
          <Link to="/wallet/add-payment-method" style={{ textDecoration: "none" }}>
            <button style={addNewButtonStyle}>
              <Plus size={18} />
              Add New
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default PaymentMethod

