import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import StripeFeeCollection from "../auth/component/stripe-collect"

const stripePromise = loadStripe(
  "pk_test_51QR3cyF9Yb7IRTrT51bShRhzJUOX9Bs4x1OA708sjW7VIaiab74CcSg2lQZvAGhJffLZdaOgghXfD4lax1zwTb9k00ThfL5yL7",
)

const AddPaymentMethod = () => {
  

  return (

      <Elements stripe={stripePromise}>
        <StripeFeeCollection type={"add"} />
      </Elements>
  )
}

export default AddPaymentMethod

