import React, { useState } from 'react';
import MenuBar from '../components/MenuBar'; // Import Sidebar component
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation

const TermsService = () => {

    const pageData = {
        name: "UHRIG",
        email: 'contact@uhrig.com',
        address: "New York",
        phone : "+1234567890",
        site : "uhrig.com",
        country : "United Kingdom",
      };
  return (
    <div className="site-page-wrapper">
      <div className="site-page-wrapper-inner">
        <div className="content-section">
            <MenuBar />
            <div className="custom-container">
                <div class="tab-content" id="en">
                    <h1>Terms of Service</h1>

                    <h2>1. Introduction</h2>

                    <h3>1.1 Purpose of the Terms of Service</h3>
                    <p>
                        These Terms of Service ("Terms") govern the use of our platform ("Service"), which provides users with a 
                        powerful scraping tool to extract product data from eBay and Amazon, referred to as "Search." By accessing 
                        or using our Service, you agree to be bound by these Terms.
                    </p>

                    <h3>1.2 Agreement to the Terms</h3>
                    <p>
                        By registering an account and using our Service, you acknowledge that you have read, understood, and agreed 
                        to comply with these Terms. If you do not agree to these Terms, you may not use the Service.
                    </p>

                    <h2>2. Account Registration and Approval</h2>

                    <h3>2.1 Registration Process</h3>
                    <p>
                        To access the Service, users must register an account by providing a valid email address and phone number. 
                        During registration, users must ensure that the information provided is accurate and complete.
                    </p>

                    <h3>2.2 Email Verification</h3>
                    <p>
                        After completing the registration process, users will receive an activation link via the email address 
                        provided. Users must click the activation link to verify their email before proceeding further.
                    </p>

                    <h3>2.3 Admin Approval and Account Activation</h3>
                    <p>
                        Once the email is verified, the user’s account will remain on hold until approved by an administrator. 
                        Upon approval, the user will be notified via email with a link to activate their account. Clicking this 
                        link will redirect the user to a payment page where they can purchase a subscription to begin using the Service.
                    </p>

                    <h2>3. Subscription and Payment</h2>

                    <h3>3.1 Subscription Details</h3>
                    <p>
                        The Service is provided on a subscription basis. Users must purchase a subscription for €9.99 per month 
                        to access the features of the platform.
                    </p>

                    <h3>3.2 Pricing and Payment Terms</h3>
                    <p>
                        The subscription fee is non-refundable. Payment will be deducted automatically every month unless the 
                        subscription is canceled by the user.
                    </p>

                    <h3>3.3 Non-Refundable Policy</h3>
                    <p>
                        All payments made for the subscription and wallet coins are non-refundable. Users are advised to carefully 
                        review the terms before making any payment.
                    </p>

                    <h3>3.4 Auto-Renewal and Cancellation</h3>
                    <p>
                        Subscriptions will automatically renew each month unless canceled by the user. Users can cancel their 
                        subscription at any time through their account settings.
                    </p>

                    <h2>4. Wallet and Coin System</h2>

                    <h3>4.1 Purchasing Coins</h3>
                    <p>
                        The platform includes a wallet system where users can purchase coins to access the "Search" page. Coins 
                        must be purchased in advance to use the scraping tool.
                    </p>

                    <h3>4.2 Usage of Coins for Search Access</h3>
                    <p>
                        Coins are required to perform searches on the platform. Each search operation will deduct a specific 
                        number of coins from the user’s wallet.
                    </p>

                    <h3>4.3 Non-Refundable Policy for Coins</h3>
                    <p>
                        Coins purchased through the wallet are non-refundable. Users are encouraged to purchase only the number 
                        of coins they expect to use.
                    </p>

                    <h2>5. User Obligations</h2>

                    <h3>5.1 Accuracy of Information</h3>
                    <p>
                        Users must provide accurate and up-to-date information during registration and when using the Service. 
                        Providing false or misleading information may result in account suspension or termination.
                    </p>

                    <h3>5.2 Compliance with Laws and Prohibited Activities</h3>
                    <p>
                        Users agree to comply with all applicable laws when using the Service. Any activities that violate laws 
                        or infringe on the rights of others are strictly prohibited.
                    </p>

                    <h2>6. Termination of Service</h2>

                    <h3>6.1 Grounds for Termination</h3>
                    <p>
                        We reserve the right to suspend or terminate any account that violates these Terms or engages in 
                        prohibited activities.
                    </p>

                    <h3>6.2 Effect of Termination on Subscription and Wallet</h3>
                    <p>
                        Upon termination of an account, any remaining subscription time or wallet coins will be forfeited 
                        and will not be refunded.
                    </p>

                    <h2>7. Disclaimers and Limitation of Liability</h2>

                    <h3>7.1 Service Availability</h3>
                    <p>
                        We do not guarantee uninterrupted availability of the Service. The platform may be unavailable at 
                        times due to maintenance or unforeseen issues.
                    </p>

                    <h3>7.2 Limitation of Liability</h3>
                    <p>
                        We shall not be held liable for any damages arising from the use or inability to use the Service.
                    </p>

                    <h2>8. Modifications to the Terms</h2>

                    <h3>8.1 Right to Modify</h3>
                    <p>
                        We reserve the right to modify these Terms at any time. Users will be notified of significant changes 
                        via email or through the platform.
                    </p>

                    <h3>8.2 User Notification of Changes</h3>
                    <p>
                        Continued use of the Service after changes to the Terms constitutes acceptance of the modified Terms.
                    </p>

                    <h2>9. Governing Law and Dispute Resolution</h2>

                    <h3>9.1 Governing Law</h3>
                    <p>
                        These Terms shall be governed by and construed in accordance with the laws of {pageData.country}.
                    </p>

                    <h3>9.2 Dispute Resolution</h3>
                    <p>
                        Any disputes arising from or relating to these Terms shall be resolved through binding arbitration 
                        in accordance with the rules of {pageData.name}
                    </p>

                    <h2>10. Contact Information</h2>
                    <p>
                        If you have any questions or concerns about these Terms, please contact us at {pageData.email}.
                    </p>
                </div>
            </div>
        </div>
        <div className="footer">
            <div className="custom-container">
                <div className="footer-main">
                    <div className="footer-logo">
                        <div className="footer-logo-image">
                            <img src="/images/logo.png" alt="" />
                        </div>
                        <div className="footer-bottom-text">
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aspernatur corrupti, voluptas facere eos, neque provident in aut, repellat architecto doloremque ipsa. Dolorum reprehenderit totam vero adipisci quos sapiente nostrum placeat!
                        </div>
                        <div className="footer-docs">
                            <a href="/privacy-policy" className="link">Privacy Policy</a>
                            <a href="/terms-of-service" className="link">Terms & Service</a>
                        </div>
                    </div>
                    <div className="footer-contact-us">
                        <div className="footer-heading">Reach Us</div>
                        <a href="mailto:sales@uhrig.com" className="link">sales@uhrig.com</a>
                    </div>
                </div>
                <div className="copyright">Copyright © 2025 urigh.com - All Rights Reserved.</div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default TermsService;