import React, { useState } from 'react';

function ContactUs() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    // Here you can handle the form submission, e.g., send the data to your server
    console.log('Form submitted:', { name, email, message });
    // Optionally, you can reset the form fields after submission
    setName('');
    setEmail('');
    setMessage('');
  };

  return (
    <div className="contact-us-container">
      <div className="contact-info">
        <h2>Contact Us</h2>
        <p>We'd love to hear from you! Please feel free to reach out with any questions or feedback.</p>

        <div className="contact-methods">
          <div className="contact-method">
            <h3>Email</h3>
            <p>
              <a href="mailto:your-email@example.com">your-email@example.com</a>
            </p>
          </div>

          <div className="contact-method">
            <h3>Phone</h3>
            <p>+1-555-123-4567</p> {/* Replace with your phone number */}
          </div>

          <div className="contact-method">
            <h3>Mail</h3>
            <address>
              Your Company Name<br />
              Street Address<br />
              City, State ZIP Code<br />
              Country
            </address>
          </div>
        </div>
      </div>

      <div className="contact-form">
        <h3>Send us a Message</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;