import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import '../../assets/css/style.css'; // Import the stylesheet
import logo from '../../assets/images/logo.png'; // Import the logo
import config from '../../lib/config'

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading,setLoading] = useState(false)
  const navigate = useNavigate(); // Hook for navigation

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true)
    try {
      const response = await fetch(`${config.BASE_URL}/user/forgot-password/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        navigate('/password-reset-verification'); // Redirect on success
      } else {
        setError('Failed to send reset link. Please try again.');
      }
    } catch (err) {
      setError('An error occurred. Please try again later.');
    }finally{
      setLoading(false)
    }
  };

  return (
    <div className="page-wrapper">
      <div className="form-container">
        {/* Logo */}
        <div className="logo-wrapper">
          <img src={logo} alt="Logo" className="logo" />
        </div>
        <h2>Forgot Password</h2>
        {error && <p style={{color:"red"}} className="error-message">{error}</p>}
        <form onSubmit={handleSubmit}>
          {/* Email Input */}
          <div style={{ marginBottom: '20px' }}>
            <input
              type="email"
              placeholder="Enter your account email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="input-field"
              required
            />
          </div>

          {/* Submit Button */}
          <div style={{ marginBottom: '20px' }}>
            <button type="submit" className="submit-btn">
             {loading ? "Loading...":"Get reset password link" }
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
