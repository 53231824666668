import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeFeeCollection from "./component/stripe-collect"; // Import your component

const stripePromise = loadStripe("pk_test_51QR3cyF9Yb7IRTrT51bShRhzJUOX9Bs4x1OA708sjW7VIaiab74CcSg2lQZvAGhJffLZdaOgghXfD4lax1zwTb9k00ThfL5yL7");

const App = () => {
  return (
    <Elements stripe={stripePromise}>
      <StripeFeeCollection type={"purchase"}  />
    </Elements>
  );
};

export default App;
