import React, { useState } from 'react';
import '../../assets/css/style.css'; // Import the stylesheet
import logo from '../../assets/images/logo.png'; // Import the logo

const AccountRecovery = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle account recovery logic (send recovery email, etc.)
    console.log('Account recovery email submitted');
  };

  return (
    <div className="page-wrapper">
      <div className="form-container">
        {/* Logo */}
        <div className="logo-wrapper">
          <img src={logo} alt="Logo" className="logo" />
        </div>
        <h2>Account Recovery</h2>
        <form onSubmit={handleSubmit}>
          {/* Email Input */}
          <div style={{ marginBottom: '20px' }}>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="input-field"
            />
          </div>

          {/* Submit Button */}
          <div style={{ marginBottom: '20px' }}>
            <button type="submit" className="submit-btn">
              Send Recovery Email
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AccountRecovery;
