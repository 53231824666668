import React from 'react';
import '../../assets/css/style.css'; // Import the stylesheet
import logo from '../../assets/images/logo.png'; // Import the logo

const RecoveryEmailSent = () => {
  return (
    <div className="page-wrapper">
      <div className="form-container">
        {/* Logo */}
        <div className="logo-wrapper">
          <img src={logo} alt="Logo" className="logo" />
        </div>
        <h2>Recovery Email Sent</h2>
        <p className='alert-text'>An email has been sent to reset your password. Please check your inbox.</p>
      </div>
    </div>
  );
};

export default RecoveryEmailSent;
