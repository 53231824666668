import React from 'react';
import '../../assets/css/style.css'; // Import the stylesheet
import logo from '../../assets/images/logo.png'; // Import the logo
import { Link } from 'react-router-dom';

const EmailVerification = () => {
  return (
    <div className="page-wrapper">
      <div className="form-container">
        {/* Logo */}
        <div className="logo-wrapper">
          <img src={logo} alt="Logo" className="logo" />
        </div>
        <h2>Email Verification</h2>
        <p className='alert-text'>A verification link has been sent to your email. Please check your inbox to verify your account.</p>
        {/* Submit Button */}
        <div style={{ marginBottom: "20px",display: "flex", justifyContent:"center", alignItems:"center", textAlign:"center"}}>
            <Link to={'/login'} type="submit" className="submit-btn" >
              {"Login"}
            </Link>
          </div>
      </div>
    </div>
  );
};

export default EmailVerification;
