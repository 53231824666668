"use client"

import { useEffect, useState, useCallback } from "react"
import { Link, useNavigate } from "react-router-dom"
import { SearchIcon, Download, ChevronLeft, ChevronRight } from "lucide-react"
import config from '../../lib/config'

const Search = () => {
  const [jobs, setJobs] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const navigate = useNavigate()

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalJobs, setTotalJobs] = useState(0)
  const [limit, setLimit] = useState(10)

  // Search state
  const [searchQuery, setSearchQuery] = useState("")

  

  // Handle search input change with debounce
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value)
  }

  

  const fetchJobs = async (page = 1) => {
    setLoading(true)
    setError(null)

    const token = localStorage.getItem("access")
    if (!token) {
      setError("Authentication token is missing")
      setLoading(false)
      return
    }

    try {
      // Build query parameters
      const queryParams = new URLSearchParams()
      queryParams.append("page", page)
      queryParams.append("limit", limit)
      

      const response = await fetch(`${config.BASE_URL}/api/jobs/getJobsList/?jobId=${searchQuery}&page=${currentPage}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if(response.status === 401){
        navigate('/')
      }

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      const data = await response.json()

      if (data.jobs) {
        setJobs(data.jobs)
        // Set pagination data
        setTotalJobs(data.totalJobs || 0)
        setCurrentPage(data.currentPage || 1)
        setTotalPages(data.totalPages || 1)
        setLimit(data.limit || 10)
      } else {
        setJobs([])
      }
    } catch (error) {
      console.error("Error fetching jobs:", error)
      setError("Failed to fetch jobs. Please try again.")
    } finally {
      setLoading(false)
    }
  }

  // Handle page change
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage)
    }
  }

  // Fetch jobs when page or search query changes
  useEffect(() => {
    fetchJobs(currentPage)
  }, [currentPage])

  // Styles
  const containerStyle = {
    padding: "20px",
    color: "#fff",
    maxWidth: "1200px",
    margin: "0 auto",
  }

  const headerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    flexWrap: "wrap",
    gap: "16px",
  }

  const titleStyle = {
    fontSize: "24px",
    fontWeight: "bold",
  }

  const addButtonStyle = {
    backgroundColor: "#E84118",
    color: "white",
    border: "none",
    borderRadius: "4px",
    padding: "10px 20px",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "bold",
  }

  const cardStyle = {
    backgroundColor: "#121212",
    borderRadius: "8px",
    padding: "20px",
    marginBottom: "20px",
  }

  const searchBarContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "16px",
  }

  const searchInputContainerStyle = {
    display: "flex",
    flex: "1",
    minWidth: "250px",
  }

  const searchInputStyle = {
    flex: "1",
    padding: "10px 16px",
    backgroundColor: "#1E1E1E",
    border: "1px solid #333",
    borderRadius: "4px 0 0 4px",
    color: "#fff",
    fontSize: "14px",
  }

  const searchButtonStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 16px",
    backgroundColor: "#333",
    border: "none",
    borderRadius: "0 4px 4px 0",
    color: "white",
    cursor: "pointer",
  }

 

  const tableContainerStyle = {
    overflowX: "auto",
  }

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "16px",
  }

  const thStyle = {
    textAlign: "left",
    padding: "12px 16px",
    borderBottom: "1px solid #333",
    color: "#999",
    fontSize: "14px",
  }

  const tdStyle = {
    padding: "12px 16px",
    borderBottom: "1px solid #222",
    fontSize: "14px",
  }

  const downloadLinkStyle = {
    color: "#4D7CFE",
    textDecoration: "none",
  }

  const paginationStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
    flexWrap: "wrap",
    gap: "16px",
  }

  const paginationInfoStyle = {
    color: "#999",
    fontSize: "14px",
  }

  const paginationControlsStyle = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  }

  const pageButtonStyle = (isActive) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "36px",
    height: "36px",
    backgroundColor: isActive ? "#E84118" : "#333",
    border: "none",
    borderRadius: "4px",
    color: "white",
    cursor: isActive ? "default" : "pointer",
    fontSize: "14px",
  })

  const navButtonStyle = (disabled) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "36px",
    height: "36px",
    backgroundColor: "#333",
    border: "none",
    borderRadius: "4px",
    color: "white",
    cursor: disabled ? "not-allowed" : "pointer",
    opacity: disabled ? 0.5 : 1,
  })

  const loadingStyle = {
    textAlign: "center",
    padding: "20px",
    color: "#999",
  }

  const errorStyle = {
    textAlign: "center",
    padding: "20px",
    color: "#FF4D4F",
  }

  const emptyStateStyle = {
    textAlign: "center",
    padding: "40px 20px",
    color: "#999",
  }

  // Generate page numbers for pagination
  const getPageNumbers = () => {
    const pageNumbers = []
    const maxVisiblePages = 5

    if (totalPages <= maxVisiblePages) {
      // Show all pages if total pages are less than max visible
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i)
      }
    } else {
      // Show a subset of pages with current page in the middle if possible
      let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2))
      const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1)

      // Adjust if we're near the end
      if (endPage - startPage + 1 < maxVisiblePages) {
        startPage = Math.max(1, endPage - maxVisiblePages + 1)
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i)
      }
    }

    return pageNumbers
  }

  return (
    <div style={containerStyle}>
      <div style={headerStyle}>
        <h1 style={titleStyle}>Search</h1>
        <div>
          <Link to="/add-file" style={{ textDecoration: "none" }}>
            <button style={addButtonStyle}>Add File</button>
          </Link>
        </div>
      </div>

      <div style={cardStyle}>
        <div style={searchBarContainerStyle}>
          <div style={searchInputContainerStyle}>
            <input
              type="text"
              placeholder="Search by Job ID..."
              style={searchInputStyle}
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <button onClick={fetchJobs} style={searchButtonStyle}>
              <SearchIcon size={18} />
            </button>
          </div>
          {/* <div>
            <button style={filterButtonStyle}>
              <Filter size={18} />
              Filter
            </button>
          </div> */}
        </div>
      </div>

      <div style={cardStyle}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "16px" }}>
          <h2 style={{ fontSize: "18px", fontWeight: "bold" }}>Jobs</h2>
        </div>

        {loading ? (
          <div style={loadingStyle}>Loading jobs...</div>
        ) : error ? (
          <div style={errorStyle}>{error}</div>
        ) : jobs.length === 0 ? (
          <div style={emptyStateStyle}>No jobs found. Try adjusting your search criteria.</div>
        ) : (
          <>
            <div style={tableContainerStyle}>
              <table style={tableStyle}>
                <thead>
                  <tr>
                    <th style={thStyle}>Job ID</th>
                    <th style={thStyle}>Date</th>
                    <th style={thStyle}>Records (Total/Skipped)</th>
                    <th style={thStyle}>Coin Usage</th>
                    <th style={thStyle}>Execution Time</th>
                    <th style={thStyle}>Status</th>
                    <th style={thStyle}>Amazon</th>
                    <th style={thStyle}>Ebay</th>
                  </tr>
                </thead>
                <tbody>
                  {jobs && jobs.map((job, index) => (
                    <tr key={job.job_id || index}>
                      <td style={tdStyle}>{job.job_id}</td>
                      <td style={tdStyle}>{job.created_at?.slice(0, 10) || "N/A"}</td>
                      <td style={tdStyle}>
                        {job.total_rows || 0} / {job.skipped_rows || 0}
                      </td>
                      <td style={tdStyle}>{job.coins_used || 0} coins</td>
                      <td style={tdStyle}>{job.execution_time || 0}s</td>
                      <td style={tdStyle}>{job.status || "Unknown"}</td>
                      <td style={tdStyle}>
                        {job.status == "Completed" && job.output_files && job.output_files[0] ? (
                          <a href={job.output_files[0]} target="_blank" style={downloadLinkStyle} rel="noreferrer">
                            {/* Download */}
                            <Download style={{color:"red"}}/>
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td style={tdStyle}>
                        {job.status == "Completed" && job.output_files && job.output_files[1] ? (
                          <a href={job.output_files[1]} target="_blank" style={downloadLinkStyle} rel="noreferrer">
                            {/* Download */}
                            <Download style={{color:"red"}}/>
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div style={paginationStyle}>
              <div style={paginationInfoStyle}>
                Showing {jobs.length} of {totalJobs} jobs
              </div>

              <div style={paginationControlsStyle}>
                <button
                  style={navButtonStyle(currentPage === 1)}
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <ChevronLeft size={18} />
                </button>

                {getPageNumbers().map((pageNum) => (
                  <button
                    key={pageNum}
                    style={pageButtonStyle(pageNum === currentPage)}
                    onClick={() => handlePageChange(pageNum)}
                    disabled={pageNum === currentPage}
                  >
                    {pageNum}
                  </button>
                ))}

                <button
                  style={navButtonStyle(currentPage === totalPages)}
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  <ChevronRight size={18} />
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Search

