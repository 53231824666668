import { Search, CheckCircle, XCircle, Coins } from "lucide-react"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Download } from "lucide-react"
import { useNavigate } from "react-router-dom"
import config from '../../lib/config'
import { useCallback } from "react"
// StatsCard component with inline styles
const StatsCard = ({ icon, stat, info, color }) => {
  const cardStyle = {
    backgroundColor: "#121212",
    borderRadius: "8px",
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  }

  const iconContainerStyle = {
    width: "50px",
    height: "50px",
    borderRadius: "25px",
    backgroundColor: `rgba(${color}, 0.1)`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }

  const statStyle = {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#fff",
    margin: "0",
  }

  const infoStyle = {
    fontSize: "14px",
    color: "#999",
    margin: "0",
  }

  

  return (
    <div style={cardStyle}>
      <div style={iconContainerStyle}>{icon}</div>
      <div>
        <p style={statStyle}>{stat}</p>
        <p style={infoStyle}>{info}</p>
      </div>
    </div>
  )
}
const Dashboard = () => {
  const navigate = useNavigate()

  const [jobs, setJobs] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalJobs, setTotalJobs] = useState(0)
  const [limit, setLimit] = useState(10)
  const [totalSearches, setTotalSearches] = useState(0);
  const [failedSearches, setFailedSearches] = useState(0);
  const [successfulSearches, setSuccessfulSearches] = useState(0);
  const [currentBalance, setCurrentBalance] = useState(0);


  // Search state
  const [searchQuery, setSearchQuery] = useState("")
  const [debouncedQuery, setDebouncedQuery] = useState("")

  // Debounce function
  const debounce = (func, delay) => {
    let timeoutId
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      timeoutId = setTimeout(() => {
        func.apply(this, args)
      }, delay)
    }
  }


   // Handle search input change with debounce
   const handleSearchChange = (e) => {
    setSearchQuery(e.target.value)
    debouncedSetQuery(e.target.value)
  }

  // Create debounced function
  const debouncedSetQuery = useCallback(
    debounce((query) => {
      setDebouncedQuery(query)
      setCurrentPage(1) // Reset to first page when search changes
    }, 500),
    [],
  )

   // Handle page change
   const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage)
    }
  }

  // Fetch jobs when page or search query changes
  useEffect(() => {
    fetchJobAnalytics()
    fetchJobs(currentPage, debouncedQuery)
  }, [currentPage, debouncedQuery])


  const fetchJobAnalytics = async () => {
    setLoading(true);
    setError(null);
  
    const token = localStorage.getItem("access");
    if (!token) {
      setError("Authentication token is missing");
      setLoading(false);
      return;
    }
  
    try {
      const response = await fetch(`${config.BASE_URL}/api/jobs/getJobAnalytics/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if(response.status === 401){
        navigate('/')
      }
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
  
      console.log(data)

      if (data) {
        setTotalSearches(data.total_searches || 0);
        setFailedSearches(data.failed_searches || 0);
        setSuccessfulSearches(data.successful_searches || 0);
        setCurrentBalance(data.current_balance || 0);
      }
    } catch (error) {
      console.error("Error fetching job analytics:", error);
      setError("Failed to fetch job analytics. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Sample data for recent jobs
  const fetchJobs = async (page = 1, jobId = "") => {
    setLoading(true)
    setError(null)



    const token = localStorage.getItem("access")
    if (!token) {
      setError("Authentication token is missing")
      setLoading(false)
      return
    }

    try {
      // Build query parameters
      const queryParams = new URLSearchParams()
      queryParams.append("page", page)
      queryParams.append("limit", limit)
      if (jobId) {
        queryParams.append("jobId", jobId)
      }

      const response = await fetch(`${config.BASE_URL}/api/jobs/getJobsList/?${queryParams.toString()}&page=1&limit=5`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if(response.status === 401){
        navigate('/')
      }

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      const data = await response.json()

      if (data.jobs) {
        setJobs(data.jobs)
        // Set pagination data
        setTotalJobs(data.totalJobs || 0)
        setCurrentPage(data.currentPage || 1)
        setTotalPages(data.totalPages || 1)
        setLimit(data.limit || 10)
      } else {
        setJobs([])
      }
    } catch (error) {
      console.error("Error fetching jobs:", error)
      setError("Failed to fetch jobs. Please try again.")
    } finally {
      setLoading(false)
    }
  }

  

  const tableContainerStyle = {
    overflowX: "auto",
  }

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "16px",
  }

  const thStyle = {
    textAlign: "left",
    padding: "12px 16px",
    borderBottom: "1px solid #333",
    color: "#999",
    fontSize: "14px",
  }

  const tdStyle = {
    padding: "12px 16px",
    borderBottom: "1px solid #222",
    fontSize: "14px",
  }

  const downloadLinkStyle = {
    color: "#4D7CFE",
    textDecoration: "none",
  }

  const paginationStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
    flexWrap: "wrap",
    gap: "16px",
  }

  const paginationInfoStyle = {
    color: "#999",
    fontSize: "14px",
  }

  const paginationControlsStyle = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  }

  const pageButtonStyle = (isActive) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "36px",
    height: "36px",
    backgroundColor: isActive ? "#E84118" : "#333",
    border: "none",
    borderRadius: "4px",
    color: "white",
    cursor: isActive ? "default" : "pointer",
    fontSize: "14px",
  })

  const navButtonStyle = (disabled) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "36px",
    height: "36px",
    backgroundColor: "#333",
    border: "none",
    borderRadius: "4px",
    color: "white",
    cursor: disabled ? "not-allowed" : "pointer",
    opacity: disabled ? 0.5 : 1,
  })

  const loadingStyle = {
    textAlign: "center",
    padding: "20px",
    color: "#999",
  }

  const errorStyle = {
    textAlign: "center",
    padding: "20px",
    color: "#FF4D4F",
  }

  const emptyStateStyle = {
    textAlign: "center",
    padding: "40px 20px",
    color: "#999",
  }


  // Styles
  const containerStyle = {
    padding: "20px",
    color: "#fff",
    maxWidth: "1200px",
    margin: "0 auto",
  }

  const headerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    flexWrap: "wrap",
    gap: "16px",
  }

  const titleStyle = {
    fontSize: "24px",
    fontWeight: "bold",
    margin: "0",
  }

  const statsContainerStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
    gap: "20px",
    marginBottom: "30px",
  }

  const cardStyle = {
    backgroundColor: "#121212",
    borderRadius: "8px",
    padding: "24px",
    marginBottom: "20px",
  }

  const cardHeaderStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "16px",
  }

  const cardTitleStyle = {
    fontSize: "18px",
    fontWeight: "bold",
    margin: "0",
  }

 

  const viewButtonStyle = {
    backgroundColor: "#333",
    color: "white",
    border: "none",
    borderRadius: "4px",
    padding: "6px 12px",
    cursor: "pointer",
    fontSize: "12px",
  }

  return (
    <div style={containerStyle}>
      <div style={headerStyle}>
        <h1 style={titleStyle}>Dashboard</h1>
      </div>

      {/* Stats Section */}
      <div style={statsContainerStyle}>
        <StatsCard icon={<Search size={24} color="#2B9943" />} stat={totalSearches} info="Total Searches" color="43, 153, 67" />
        <StatsCard
          icon={<CheckCircle size={24} color="#2B9943" />}
          stat={successfulSearches}
          info="Successful Searches"
          color="43, 153, 67"
        />
        <StatsCard
          icon={<XCircle size={24} color="#E71D36" />}
          stat={failedSearches}
          info="Failed Searches"
          color="231, 29, 54"
        />
        <StatsCard icon={<Coins size={24} color="#E7E71D" />} stat={currentBalance} info="Balance" color="231, 231, 29" />
      </div>

      {/* Recent Jobs */}
      <div style={cardStyle}>
        <div style={cardHeaderStyle}>
          <h2 style={cardTitleStyle}>Recent Jobs</h2>
          <Link to="/search" style={{ textDecoration: "none", color: "#4D7CFE", fontSize: "14px" }}>
            View All
          </Link>
        </div>

        {/* Recent Jobs Table */}
        {/* <div style={tableContainerStyle}>
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={thStyle}>Job ID</th>
                <th style={thStyle}>Date</th>
                <th style={thStyle}>Records (Total/Skipped)</th>
                <th style={thStyle}>Coin Usage</th>
                <th style={thStyle}>Timespan</th>
                <th style={thStyle}>Execution Time</th>
                <th style={thStyle}>Status</th>
                <th style={thStyle}>Action</th>
              </tr>
            </thead>
            <tbody>
              {recentJobs.map((job, index) => (
                <tr key={job.job_id}>
                  <td style={tdStyle}>{job.job_id}</td>
                  <td style={tdStyle}>{new Date(job.created_at).toLocaleDateString()}</td>
                  <td style={tdStyle}>
                    {job.total_rows} / {job.skipped_rows}
                  </td>
                  <td style={tdStyle}>{job.coins_used} coins</td>
                  <td style={tdStyle}>{job.timespan}</td>
                  <td style={tdStyle}>{job.execution_time}s</td>
                  <td style={tdStyle}>{job.status}</td>
                  <td style={tdStyle}>
                    <button style={viewButtonStyle}>View</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div> */}
        {loading ? (
          <div style={loadingStyle}>Loading jobs...</div>
        ) : error ? (
          <div style={errorStyle}>{error}</div>
        ) : jobs.length === 0 ? (
          <div style={emptyStateStyle}>No jobs found. Try adjusting your search criteria.</div>
        ) : (
          <>
            <div style={tableContainerStyle}>
              <table style={tableStyle}>
                <thead>
                  <tr>
                    <th style={thStyle}>Job ID</th>
                    <th style={thStyle}>Date</th>
                    <th style={thStyle}>Records (Total/Skipped)</th>
                    <th style={thStyle}>Coin Usage</th>
                    <th style={thStyle}>Execution Time</th>
                    <th style={thStyle}>Status</th>
                    <th style={thStyle}>Amazon</th>
                    <th style={thStyle}>Ebay</th>
                  </tr>
                </thead>
                <tbody>
                  {jobs && jobs.map((job, index) => (
                    <tr key={job.job_id || index}>
                      <td style={tdStyle}>{job.job_id}</td>
                      <td style={tdStyle}>{job.created_at?.slice(0, 10) || "N/A"}</td>
                      <td style={tdStyle}>
                        {job.total_rows || 0} / {job.skipped_rows || 0}
                      </td>
                      <td style={tdStyle}>{job.coins_used || 0} coins</td>
                      <td style={tdStyle}>{job.execution_time || 0}s</td>
                      <td style={tdStyle}>{job.status || "Unknown"}</td>
                      <td style={tdStyle}>
                        {job.status == "Completed" && job.output_files && job.output_files[0] ? (
                          <a href={job.output_files[0]} target="_blank" style={downloadLinkStyle} rel="noreferrer">
                            {/* Download */}
                            <Download style={{color:"red"}}/>
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td style={tdStyle}>
                        {job.status == "Completed" && job.output_files && job.output_files[1] ? (
                          <a href={job.output_files[1]} target="_blank" style={downloadLinkStyle} rel="noreferrer">
                            {/* Download */}
                            <Download style={{color:"red"}}/>
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* <div style={paginationStyle}>
              <div style={paginationInfoStyle}>
                Showing {jobs.length} of {totalJobs} jobs
              </div>

              <div style={paginationControlsStyle}>
                <button
                  style={navButtonStyle(currentPage === 1)}
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <ChevronLeft size={18} />
                </button>

                {getPageNumbers().map((pageNum) => (
                  <button
                    key={pageNum}
                    style={pageButtonStyle(pageNum === currentPage)}
                    onClick={() => handlePageChange(pageNum)}
                    disabled={pageNum === currentPage}
                  >
                    {pageNum}
                  </button>
                ))}

                <button
                  style={navButtonStyle(currentPage === totalPages)}
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  <ChevronRight size={18} />
                </button>
              </div>
            </div> */}
          </>
        )}

      </div>
    </div>
  )
}

export default Dashboard

