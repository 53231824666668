import { useEffect, useState } from "react";
import { AppContext } from "./AppContext";
import config from '../lib/config'
export const AppProvider = ({ children }) => {
  const [total_records, setTotal_records] = useState(0);
  const [estimation_cost, setEstimation_cost] = useState(0);
  const [estimation_time, setEstimation_time] = useState("0 minutes");
  const [file, setFile] = useState(null);
  const [jobName, setJobName] = useState("");

  //subsscription check
  const fetchUserProfile = async () => {
    const token = localStorage.getItem("access")
    const currentPath = window.location.pathname

     
    // Check if the path starts with /verify-email/ and has dynamic segments
    if (currentPath.startsWith('/verify-email/') && currentPath.split('/').length > 3) {
      return;  // Skip the function
    }
      
      const ignoredPaths = ['/', '/login', '/register', '/forgot-password', '/password-reset-verification', '/email-verification','/verify-email']
    
    if (ignoredPaths.includes(currentPath)) return
  
    if (!token) {
      console.log('Missing token')
      window.location.href = '/'
      return
    }
  
    try {
      const response = await fetch(`${config.BASE_URL}/user/profile/update/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
  
      if (response.status === 401) {
        console.log('Unauthorized')
        window.location.href = '/'
        return
      }
  
      if (!response.ok) {
        throw new Error("Failed to fetch profile data")
      }
  
      const data = await response.json()
      console.log('Subscription status:', data?.subscription.status)
  
      if (data?.subscription.status !== "active") {
        window.location.href = '/'
      }
  
    } catch (err) {
      window.location.href = '/'
    }
  }
  

  useEffect(()=>{
    fetchUserProfile()
  },[])
  return (
    <AppContext.Provider
      value={{
        file,
        jobName,
        total_records,
        estimation_time,
        estimation_cost,
        setFile,
        setJobName,
        setTotal_records,
        setEstimation_cost,
        setEstimation_time,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
