"use client"

import { useState, useEffect } from "react"
import Footer from "../components/Footer"
import { Link } from "react-router-dom"
import Navbar from "../components/Navbar"

const Home = () => {
  // State to track window dimensions
  const [windowDimensions, setWindowDimensions] = useState({
    width: typeof window !== "undefined" ? window.innerWidth : 1200,
    height: typeof window !== "undefined" ? window.innerHeight : 800,
  })

  // Update dimensions on window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  // Determine if mobile view
  const isMobile = windowDimensions.width < 768
  const isTablet = windowDimensions.width >= 768 && windowDimensions.width < 1024

  // All styles in a single object
  const styles = {
    // Page container
    pageContainer: {
      width: "100%",
      minHeight: "100vh",
      margin: 0,
      padding: 0,
      fontFamily: "Arial, sans-serif",
      overflowX: "hidden",
      backgroundColor: "#fff",
      color: "#333",
    },

    // Menu Bar
    menuBar: {
      backgroundColor: "#070808",
      padding: "15px 0",
      width: "100%",
    },
    menuContainer: {
      width: "100%",
      maxWidth: "1200px",
      margin: "0 auto",
      padding: "0 5px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    logo: {
      height: "30px",
    },
    buttonsContainer: {
    },
    signInButton: {
      padding: "10px 20px",
      backgroundColor: "transparent",
      color: "#ff0000",
      border: "1px solid #ff0000",
      borderRadius: "4px",
      cursor: "pointer",
      fontWeight: "bold",
      transition: "background-color 0.3s ease",
      marginRight:"10px"
    },
    signUpButton: {
      padding: "10px 20px",
      backgroundColor: "#ff0000",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      fontWeight: "bold",
      transition: "background-color 0.3s ease",
    },

    // Hero Section
    heroSection: {
      backgroundColor: "#070808",
      color: "#fff",
      padding: isMobile ? "40px 0" : "60px 0",
      position: "relative",
    },
    heroContainer: {
      width: "100%",
      maxWidth: "1200px",
      margin: "0 auto",
      padding: "0 20px",
    },
    heroContent: {
      display: "flex",
      flexDirection: isMobile || isTablet ? "column" : "row",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "40px",
    },
    heroTextSection: {
      flex: "1",
      maxWidth: isMobile || isTablet ? "100%" : "50%",
    },
    heroHeading: {
      fontSize: isMobile ? "28px" : isTablet ? "36px" : "42px",
      fontWeight: "bold",
      marginBottom: "20px",
      lineHeight: 1.2,
    },
    heroCta: {
      marginTop: "30px",
    },
    ctaButton: {
      display: "inline-block",
      padding: "12px 24px",
      backgroundColor: "#ff0000",
      color: "#fff",
      textDecoration: "none",
      borderRadius: "4px",
      fontWeight: "bold",
      fontSize: "16px",
      border: "none",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
    },
    heroMockup: {
      flex: "1",
      maxWidth: isMobile || isTablet ? "100%" : "50%",
    },
    mockupBox: {
      backgroundColor: "#1A1A1B",
      borderRadius: "8px",
      overflow: "hidden",
      boxShadow: "0 10px 30px rgba(0,0,0,0.2)",
    },
    mockupHeader: {
      backgroundColor: "#2A2A2B",
      padding: "10px",
      display: "flex",
      gap: "8px",
    },
    mockupDot: {
      width: "12px",
      height: "12px",
      borderRadius: "50%",
      display: "inline-block",
    },
    redDot: {
      backgroundColor: "#ff5f56",
    },
    yellowDot: {
      backgroundColor: "#ffbd2e",
    },
    greenDot: {
      backgroundColor: "#27c93f",
    },
    mockupScreen: {
      padding: "10px",
    },
    mockupImage: {
      width: "100%",
      height: "auto",
      display: "block",
    },

    // Divider
    divider: {
      width: "100%",
      height: "auto",
      display: "block",
    },

    // Features Section
    featuresSection: {
      backgroundColor: "#1A1A1B",
      color: "#fff",
      padding: isMobile ? "40px 0" : "80px 0",
      textAlign: "center",
    },
    sectionHeading: {
      fontSize: isMobile ? "28px" : "36px",
      fontWeight: "bold",
      marginBottom: "20px",
      textAlign: "center",
    },
    sectionSubheading: {
      color: "#ccc",
      maxWidth: "800px",
      margin: "0 auto 40px",
      fontSize: "18px",
      lineHeight: 1.6,
    },
    featureContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "80px",
      maxWidth: "1000px",
      margin: "0 auto",
    },
    featureItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
    featureIcon: {
      width: "100px",
      height: "100px",
      marginBottom: "24px",
    },
    featureTitle: {
      fontSize: "24px",
      fontWeight: "bold",
      marginBottom: "16px",
    },
    featureDescription: {
      color: "#ccc",
      lineHeight: 1.6,
      fontSize: "16px",
      maxWidth: "800px",
      margin: "0 auto",
    },

    // Workflow Section
    workflowSection: {
      backgroundColor: "#070808",
      color: "#fff",
      padding: isMobile ? "40px 0" : "80px 0",
    },
    workflowImage: {
      width: "100%",
      maxWidth: "100%",
      height: "auto",
      marginTop: "30px",
      borderRadius: "8px",
    },

    // Pricing Section
    pricingSection: {
      backgroundColor: "#1A1A1B",
      color: "#fff",
      padding: isMobile ? "40px 0" : "80px 0",
      textAlign: "center",
    },
    pricingHeading: {
      fontSize: isMobile ? "28px" : "36px",
      fontWeight: "bold",
      marginBottom: "30px",
    },

    
  }

  return (
    <div style={styles.pageContainer}>
      
      <Navbar/>

      {/* Hero Section */}
      <div style={styles.heroSection}>
        <div style={styles.heroContainer}>
          <div style={styles.heroContent}>
            <div style={styles.heroTextSection}>
              <h1 style={styles.heroHeading}>Effortless Data Extraction, Simplified</h1>
              <div style={styles.heroCta}>
              <Link to={'/register'}>
                <button style={styles.ctaButton}>Sign Up Now</button>
             </Link>
              </div>
            </div>
            <div style={styles.heroMockup}>
              <div style={styles.mockupBox}>
                <div style={styles.mockupHeader}>
                  <span style={{ ...styles.mockupDot, ...styles.redDot }}></span>
                  <span style={{ ...styles.mockupDot, ...styles.yellowDot }}></span>
                  <span style={{ ...styles.mockupDot, ...styles.greenDot }}></span>
                </div>
                <div style={styles.mockupScreen}>
                  <img src="/images/hero-section.png" alt="Data extraction interface" style={styles.mockupImage} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Divider */}
      <svg width="100%" style={styles.divider} viewBox="0 0 1439 248" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M783 116.5L0 248V0.00012207L1439 0.00012207L783 116.5Z" fill="#070808" />
      </svg>

      {/* Features Section */}
      <div style={styles.featuresSection}>
        <div style={styles.heroContainer}>
          <h2 style={styles.sectionHeading}>Features</h2>
          <p style={styles.sectionSubheading}>
            Unlock the full potential of data extraction with our powerful, easy-to-use scraping tool.
          </p>

          <div style={styles.featureContainer}>
            {/* Feature 1 */}
            <div style={styles.featureItem}>
              <img src="/images/feature-01.png" alt="Easy to use interface" style={styles.featureIcon} />
              <h3 style={styles.featureTitle}>Easy to use interface</h3>
              <p style={styles.featureDescription}>
                Our platform is built with an easy-to-use interface, ensuring that users of all skill levels can quickly
                navigate and perform scraping tasks without a steep learning curve. Whether you're a first-time user or
                an experienced data professional, the intuitive design helps you get started right away.
              </p>
            </div>

            {/* Feature 2 */}
            <div style={styles.featureItem}>
              <img src="/images/feature-02.png" alt="Powerful Scraping Capabilities" style={styles.featureIcon} />
              <h3 style={styles.featureTitle}>Powerful Scraping Capabilities</h3>
              <p style={styles.featureDescription}>
                Behind the simplicity lies powerful scraping capabilities. With support for leading platforms like
                Amazon and eBay, our tool efficiently handles complex data extraction tasks, offering speed, accuracy,
                and scalability. It's designed to retrieve all the essential product information you need with minimal
                effort.
              </p>
            </div>

            {/* Feature 3 */}
            <div style={styles.featureItem}>
              <img src="/images/feature-03.png" alt="Data Cleaning and Transformation" style={styles.featureIcon} />
              <h3 style={styles.featureTitle}>Data Cleaning and Transformation</h3>
              <p style={styles.featureDescription}>
                Once the data is collected, our data cleaning and transformation features step in to refine your
                results. Automatically remove duplicates, standardize formats, and apply transformations to create
                clean, structured datasets ready for immediate use. This ensures your data is not only complete but also
                perfectly organized for analysis, reporting, or integration into other systems.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Divider */}
      <svg width="100%" style={styles.divider} viewBox="0 0 1439 248" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M783 116.5L0 248V0.00012207L1439 0.00012207L783 116.5Z" fill="#1A1A1B" />
      </svg>

      {/* Workflow Section */}
      <div style={styles.workflowSection}>
        <div style={styles.heroContainer}>
          <h2 style={styles.sectionHeading}>Work Flow</h2>
          <p style={styles.sectionSubheading}>
            Unlock the full potential of data extraction with our powerful, easy-to-use scraping tool.
          </p>
          <img src="/images/flow.png" alt="Workflow diagram" style={styles.workflowImage} />
        </div>
      </div>

      {/* Divider */}
      <svg width="100%" style={styles.divider} viewBox="0 0 1439 248" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M783 116.5L0 248V0.00012207L1439 0.00012207L783 116.5Z" fill="#1A1A1B" />
      </svg>

      {/* Pricing Section */}
      <div style={styles.pricingSection}>
        <div style={styles.heroContainer}>
          <h2 style={styles.pricingHeading}>Let's Start it today at 9.99€/month</h2>
          <Link to={'/register'}>
          <button style={styles.ctaButton}>Sign Up Today</button>
          </Link>
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default Home
