


import { useState } from "react"
import "../../assets/css/style.css" // Import the stylesheet
import logo from "../../assets/images/logo.png" // Import the logo
import { useNavigate } from "react-router-dom"
import config from '../../lib/config'
const Register = () => {
  const navigate = useNavigate()
  const [username, setUsername] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const validateForm = () => {
    const newErrors = {}

    if (!username) {
      newErrors.username = ["Username is required"]
    }

    if (!email) {
      newErrors.email = ["Email is required"]
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = ["Email is invalid"]
    }

    if (!password) {
      newErrors.password = ["Password is required"]
    } else if (password.length < 3) {
      newErrors.password = ["Password must be at least 3 characters"]
    }

    if (password !== confirmPassword) {
      newErrors.confirmPassword = ["Passwords do not match"]
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!validateForm()) {
      return
    }

    setIsLoading(true)

    try {
      const response = await fetch(`${config.BASE_URL}/user/signup/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          email,
          password,
        }),
      })

      const data = await response.json()

      if (!response.ok) {
        setErrors(data)
      } else {
        // Successful registration
        console.log("Registration successful:", data)
        navigate("/email-verification")
      }
    } catch (error) {
      setErrors({
        general: ["An error occurred. Please try again."],
      })
      console.error("Registration error:", error)
    } finally {
      setIsLoading(false)
    }
  }

  const renderErrors = (field) => {
    if (errors[field]) {
      return (
        <div style={{color:"red"}}>
          {errors[field].map((error, index) => (
            <p key={index}>{error}</p>
          ))}
        </div>
      )
    }
    return null
  }

  return (
    <div className="page-wrapper">
      <div className="form-container">
        {/* Logo */}
        <div className="logo-wrapper">
          <img src={logo || "/placeholder.svg"} alt="Logo" className="logo" />
        </div>
        <h2>Register</h2>

        {errors.general && renderErrors("general")}

        <form onSubmit={handleSubmit}>
          {/* Username Input */}
          <div style={{ marginBottom: "20px" }}>
            <input
              type="text"
              name="username"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="input-field"
              disabled={isLoading}
            />
            {renderErrors("username")}
          </div>

          {/* Email Input */}
          <div style={{ marginBottom: "20px" }}>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="input-field"
              disabled={isLoading}
            />
            {renderErrors("email")}
          </div>

          {/* Password Input */}
          <div style={{ marginBottom: "20px" }}>
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="input-field"
              disabled={isLoading}
            />
            {renderErrors("password")}
          </div>

          {/* Confirm Password Input */}
          <div style={{ marginBottom: "20px" }}>
            <input
              type="password"
              name="confirmPassword"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="input-field"
              disabled={isLoading}
            />
            {renderErrors("confirmPassword")}
          </div>

          {/* Submit Button */}
          <div style={{ marginBottom: "20px" }}>
            <button type="submit" className="submit-btn" disabled={isLoading}>
              {isLoading ? "Registering..." : "Register"}
            </button>
          </div>
        </form>

        {/* Links */}
        <div className="links">
          <a href="/login">Already have an account? Login</a>
        </div>
      </div>
    </div>
  )
}

export default Register

