import React from 'react'
import { Link } from 'react-router-dom'

const Navbar = () => {

    
    
      const styles = {
      
    
        // Menu Bar
        menuBar: {
          backgroundColor: "#070808",
          padding: "15px 0",
          width: "100%",
        },
        menuContainer: {
          width: "100%",
          maxWidth: "1200px",
          margin: "0 auto",
          padding: "0 5px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
        logo: {
          height: "30px",
        },
        buttonsContainer: {
        },
        signInButton: {
          padding: "10px 20px",
          backgroundColor: "transparent",
          color: "#ff0000",
          border: "1px solid #ff0000",
          borderRadius: "4px",
          cursor: "pointer",
          fontWeight: "bold",
          transition: "background-color 0.3s ease",
          marginRight:"10px"
        },
        signUpButton: {
          padding: "10px 20px",
          backgroundColor: "#ff0000",
          color: "#fff",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
          fontWeight: "bold",
          transition: "background-color 0.3s ease",
        },
    }
  return (
    <>
        {/* Menu Bar */}
      <div style={styles.menuBar}>
        <div style={styles.menuContainer}>
          <a href="/">
            <img src="/images/logo.png" alt="Logo" style={styles.logo} />
          </a>

          <div >
                <Link to={'/login'}>
            <button  style={styles.signInButton}>
                Sign In
                </button>
                </Link>
            {/* <button style={styles.signUpButton}>Sign Up</button> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar