import { useState, useEffect } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { LayoutGrid, Search, DollarSign, Settings, LogOut, Menu, X, User } from "lucide-react"

const Sidebar = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  // Function to check if the current link is active
  const isActive = (path) => location.pathname === path

  // Logout function to clear localStorage and navigate to home
  const handleLogout = () => {
    localStorage.clear()
    navigate("/")
  }

  // Toggle mobile menu
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen)
  }

  // Close mobile menu when navigating
  const handleNavigation = () => {
    if (windowWidth < 768) {
      setIsMobileMenuOpen(false)
    }
  }

  // Update window width on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
      if (window.innerWidth >= 768) {
        setIsMobileMenuOpen(false)
      }
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  // Styles
  const sidebarContainerStyle = {
    position: "relative",
    zIndex: 1000,
  }

  const mobileToggleStyle = {
    display: windowWidth < 768 && !isMobileMenuOpen ? "flex" : "none",
    position: "fixed",
    top: "10px",
    left: "10px",
    zIndex: 1001,
    backgroundColor: "#121212",
    color: "#e84118",
    border: "none",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)",
  }
  

  const sidebarStyle = {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    backgroundColor: "#121212",
    color: "white",
    width: windowWidth < 768 ? "100%" : "250px",
    padding: "20px 0",
    position: windowWidth < 768 ? "fixed" : "sticky",
    top: 0,
    left: 0,
    transform: windowWidth < 768 && !isMobileMenuOpen ? "translateX(-100%)" : "translateX(0)",
    transition: "transform 0.3s ease-in-out",
    overflowY: "auto",
    boxShadow: windowWidth < 768 ? "0 0 15px rgba(0, 0, 0, 0.5)" : "none",
    zIndex: 1000,
  }

  const logoWrapperStyle = {
    padding: "0 20px 30px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }

  const logoTextStyle = {
    color: "#e84118",
    fontSize: "24px",
    fontWeight: "bold",
    margin: 0,
    padding: "10px 0",
  }

  const closeButtonStyle = {
    display: windowWidth < 768 ? "flex" : "none",
    backgroundColor: "transparent",
    border: "none",
    color: "white",
    cursor: "pointer",
    padding: "5px",
  }

  const navLinksStyle = {
    listStyle: "none",
    padding: 0,
    margin: 0,
  }

  const navItemStyle = (isActiveLink) => ({
    marginBottom: "5px",
    backgroundColor: isActiveLink ? "#e84118" : "transparent",
    borderRadius: "8px",
    margin: "0 10px 5px 10px",
  })

  const navLinkStyle = {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    padding: "12px 20px",
    textDecoration: "none",
    color: "white",
    transition: "background-color 0.3s",
    fontSize: "16px",
    borderRadius: "8px",
  }

  const sidebarBottomStyle = {
    marginTop: "auto",
    padding: "0 20px 20px",
  }

  const profileButtonStyle = {
    backgroundColor: "#1E1E1E",
    borderRadius: "8px",
    marginBottom: "10px",
  }

  const profileLinkStyle = {
    display: "flex",
    alignItems: "center",
    padding: "12px 15px",
    textDecoration: "none",
    color: "white",
  }

  const avatarStyle = {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    overflow: "hidden",
    marginRight: "15px",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }

  const avatarImageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  }

  const chevronStyle = {
    marginLeft: "auto",
    fontSize: "20px",
  }

  const logoutButtonStyle = {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    width: "100%",
    padding: "12px 15px",
    background: "none",
    border: "none",
    color: "white",
    cursor: "pointer",
    textAlign: "left",
    fontSize: "16px",
    borderRadius: "8px",
  }

  const overlayStyle = {
    display: windowWidth < 768 && isMobileMenuOpen ? "block" : "none",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  }

  const contentPushStyle = {
    marginLeft: windowWidth >= 768 ? "250px" : "0",
    transition: "margin-left 0.3s ease-in-out",
  }

  return (
    <div style={sidebarContainerStyle}>
      {/* Mobile menu toggle button */}
      <button style={mobileToggleStyle} onClick={toggleMobileMenu} aria-label="Toggle menu">
        <Menu size={24} />
      </button>

      {/* Overlay for mobile */}
      <div style={overlayStyle} onClick={toggleMobileMenu}></div>

      {/* Sidebar */}
      <div style={sidebarStyle}>
        <div style={logoWrapperStyle}>
          <h1 style={logoTextStyle}>UHRIG</h1>
          <button style={closeButtonStyle} onClick={toggleMobileMenu} aria-label="Close menu">
            <X size={24} />
          </button>
        </div>

        <ul style={navLinksStyle}>
          <li style={navItemStyle(isActive("/dashboard"))}>
            <Link style={navLinkStyle} to="/dashboard" onClick={handleNavigation}>
              <LayoutGrid size={20} />
              Dashboard
            </Link>
          </li>
          <li style={navItemStyle(isActive("/search") || isActive("/add-file") || isActive("/add-file/summary"))}>
            <Link style={navLinkStyle} to="/search" onClick={handleNavigation}>
              <Search size={20} />
              Search
            </Link>
          </li>
          <li style={navItemStyle(isActive("/wallet"))}>
            <Link style={navLinkStyle} to="/wallet" onClick={handleNavigation}>
              <DollarSign size={20} />
              Wallet
            </Link>
          </li>
          <li style={navItemStyle(isActive("/setting"))}>
            <Link style={navLinkStyle} to="/setting" onClick={handleNavigation}>
              <Settings size={20} />
              Setting
            </Link>
          </li>
        </ul>

        {/* Bottom section for profile and logout */}
        <div style={sidebarBottomStyle}>
          <div style={profileButtonStyle}>
            <Link to="#" style={profileLinkStyle} onClick={handleNavigation}>
              <div style={avatarStyle}>
                {windowWidth < 768 ? (
                  <User size={20} color="#121212" />
                ) : (
                  <img
                    src="http://img.freepik.com/free-vector/blue-circle-with-white-user_78370-4707.jpg?ga=GA1.1.953659875.1710991192&semt=ais_hybrid"
                    alt="Profile"
                    style={avatarImageStyle}
                  />
                )}
              </div>
              <span>Profile</span>
              {/* <span style={chevronStyle}>›</span> */}
            </Link>
          </div>
          <button onClick={handleLogout} style={logoutButtonStyle}>
            <LogOut size={20} />
            <span>Logout</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Sidebar

