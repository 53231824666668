import React, { useState } from 'react';
import MenuBar from '../components/MenuBar'; // Import Sidebar component
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation

const About = () => {

    const pageData = {
        name: "UHRIG",
        email: 'contact@uhrig.com',
        address: "New York",
        phone : "+1234567890",
        site : "uhrig.com",
        country : "United Kingdom",
      };
  return (
    <div className="site-page-wrapper">
      <div className="site-page-wrapper-inner">
        <div className="content-section">
            <MenuBar />
            <div className="custom-container">
                <div class="about-us-content">
                    <div className="about-us-content-card">
                        <div className="about-us-content-card-grid">
                            <div className="about-us-content-text">
                                <div className="heading">About Us</div>
                                <div className="content">
                                    We are UHRIG Solutions, a technology company dedicated to providing innovative solutions for e-commerce data extraction. Our flagship service, "Search," is a powerful scraping tool designed to help businesses and individuals efficiently gather product data from popular online marketplaces like eBay and Amazon.
                                </div>
                            </div>
                            <div className="about-us-content-image">
                                <img src="/images/about.jpg" alt="" />
                            </div>
                        </div>
                        <div className="about-us-content-card-grid" style={{marginTop:'50px'}}>
                            <div className="about-us-content-image">
                                <img src="/images/vission.jpg" alt="" />
                            </div>
                            <div className="about-us-content-text">
                                <div className="heading">Our Vision</div>
                                <div className="content">
                                Our vision is to empower users with the data they need to make informed decisions and gain a competitive edge in the online marketplace. We strive to simplify the process of data acquisition, enabling users to focus on analysis, strategy, and growth.
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    {/* <h1 style={{textAlign:'center'}}>About Us</h1>
                    <p style={{textAlign:'center'}}>
                        We are UHRIG Solutions, a technology company dedicated to providing innovative solutions for e-commerce data extraction. Our flagship service, "Search," is a powerful scraping tool designed to help businesses and individuals efficiently gather product data from popular online marketplaces like eBay and Amazon.
                    </p>
                    <h1 style={{textAlign:'center'}}>Our Vision</h1>
                    <p style={{textAlign:'center'}}>
                        Our vision is to empower users with the data they need to make informed decisions and gain a competitive edge in the online marketplace. We strive to simplify the process of data acquisition, enabling users to focus on analysis, strategy, and growth.
                    </p> */}
                </div>
            </div>
        </div>
        <div className="footer">
            <div className="custom-container">
                <div className="footer-main">
                    <div className="footer-logo">
                        <div className="footer-logo-image">
                            <img src="/images/logo.png" alt="" />
                        </div>
                        <div className="footer-bottom-text">
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aspernatur corrupti, voluptas facere eos, neque provident in aut, repellat architecto doloremque ipsa. Dolorum reprehenderit totam vero adipisci quos sapiente nostrum placeat!
                        </div>
                        <div className="footer-docs">
                            <a href="/privacy-policy" className="link">Privacy Policy</a>
                            <a href="/terms-of-service" className="link">Terms & Service</a>
                        </div>
                    </div>
                    <div className="footer-contact-us">
                        <div className="footer-heading">Reach Us</div>
                        <a href="mailto:sales@uhrig.com" className="link">sales@uhrig.com</a>
                    </div>
                </div>
                <div className="copyright">Copyright © 2025 urigh.com - All Rights Reserved.</div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default About;

// "use client"

// import { useState, useEffect } from "react"

// const About = () => {
//   // State to track window dimensions
//   const [windowDimensions, setWindowDimensions] = useState({
//     width: typeof window !== "undefined" ? window.innerWidth : 1200,
//     height: typeof window !== "undefined" ? window.innerHeight : 800,
//   })

//   // Update dimensions on window resize
//   useEffect(() => {
//     const handleResize = () => {
//       setWindowDimensions({
//         width: window.innerWidth,
//         height: window.innerHeight,
//       })
//     }

//     window.addEventListener("resize", handleResize)
//     return () => window.removeEventListener("resize", handleResize)
//   }, [])

//   // Determine if mobile view
//   const isMobile = windowDimensions.width < 768
//   const isTablet = windowDimensions.width >= 768 && windowDimensions.width < 1024

//   // All styles in a single object
//   const styles = {
//     // Page container
//     pageContainer: {
//       width: "100%",
//       minHeight: "100vh",
//       margin: 0,
//       padding: 0,
//       fontFamily: "Arial, sans-serif",
//       overflowX: "hidden",
//       backgroundColor: "#fff",
//       color: "#333",
//     },

//     // Menu Bar
//     menuBar: {
//       backgroundColor: "#070808",
//       padding: "15px 0",
//       width: "100%",
//     },
//     menuContainer: {
//       width: "100%",
//       maxWidth: "1200px",
//       margin: "0 auto",
//       padding: "0 20px",
//       display: "flex",
//       justifyContent: "space-between",
//       alignItems: "center",
//     },
//     logo: {
//       height: "30px",
//     },
//     buttonsContainer: {
//       display: "flex",
//       gap: "10px",
//     },
//     signInButton: {
//       padding: "10px 20px",
//       backgroundColor: "transparent",
//       color: "#ff0000",
//       border: "1px solid #ff0000",
//       borderRadius: "4px",
//       cursor: "pointer",
//       fontWeight: "bold",
//       transition: "background-color 0.3s ease",
//     },
//     signUpButton: {
//       padding: "10px 20px",
//       backgroundColor: "#ff0000",
//       color: "#fff",
//       border: "none",
//       borderRadius: "4px",
//       cursor: "pointer",
//       fontWeight: "bold",
//       transition: "background-color 0.3s ease",
//     },

//     // Hero Section
//     heroSection: {
//       backgroundColor: "#070808",
//       color: "#fff",
//       padding: isMobile ? "40px 0" : "60px 0",
//       position: "relative",
//     },
//     heroContainer: {
//       width: "100%",
//       maxWidth: "1200px",
//       margin: "0 auto",
//       padding: "0 20px",
//     },
//     heroContent: {
//       textAlign: "center",
//       maxWidth: "800px",
//       margin: "0 auto",
//     },
//     heroHeading: {
//       fontSize: isMobile ? "28px" : isTablet ? "36px" : "42px",
//       fontWeight: "bold",
//       marginBottom: "20px",
//       lineHeight: 1.2,
//     },
//     heroSubheading: {
//       fontSize: isMobile ? "16px" : "18px",
//       lineHeight: 1.6,
//       color: "#ccc",
//       marginBottom: "40px",
//     },

//     // Divider
//     divider: {
//       width: "100%",
//       height: "auto",
//       display: "block",
//     },

//     // About Section
//     aboutSection: {
//       backgroundColor: "#1A1A1B",
//       color: "#fff",
//       padding: isMobile ? "40px 0" : "80px 0",
//     },
//     aboutContainer: {
//       width: "100%",
//       maxWidth: "1200px",
//       margin: "0 auto",
//       padding: "0 20px",
//     },
//     sectionHeading: {
//       fontSize: isMobile ? "24px" : "30px",
//       fontWeight: "bold",
//       marginBottom: "20px",
//       textAlign: "center",
//     },
//     sectionSubheading: {
//       fontSize: isMobile ? "16px" : "18px",
//       lineHeight: 1.6,
//       color: "#ccc",
//       marginBottom: "40px",
//       textAlign: "center",
//       maxWidth: "800px",
//       margin: "0 auto 40px",
//     },
//     aboutContent: {
//       display: "flex",
//       flexDirection: isMobile ? "column" : "row",
//       gap: "40px",
//       alignItems: "center",
//       marginBottom: "80px",
//     },
//     aboutImage: {
//       flex: "1",
//       maxWidth: isMobile ? "100%" : "45%",
//     },
//     aboutImageContainer: {
//       backgroundColor: "#2A2A2B",
//       borderRadius: "8px",
//       overflow: "hidden",
//       boxShadow: "0 10px 30px rgba(0,0,0,0.2)",
//     },
//     aboutText: {
//       flex: "1",
//       maxWidth: isMobile ? "100%" : "55%",
//     },
//     aboutParagraph: {
//       marginBottom: "20px",
//       lineHeight: 1.6,
//       fontSize: "16px",
//       color: "#ccc",
//     },
//     redText: {
//       color: "#ff0000",
//       fontWeight: "bold",
//     },

//     // Mission Section
//     missionSection: {
//       marginBottom: "80px",
//     },
//     missionContent: {
//       backgroundColor: "#070808",
//       padding: "40px",
//       borderRadius: "8px",
//       textAlign: "center",
//     },
//     missionStatement: {
//       fontSize: isMobile ? "20px" : "24px",
//       fontWeight: "bold",
//       marginBottom: "20px",
//       lineHeight: 1.4,
//     },

//     // Stats Section
//     statsSection: {
//       marginBottom: "80px",
//     },
//     statsGrid: {
//       display: "flex",
//       flexWrap: "wrap",
//       justifyContent: "space-between",
//       gap: "20px",
//     },
//     statItem: {
//       flex: "1 1 200px",
//       backgroundColor: "#070808",
//       padding: "30px 20px",
//       borderRadius: "8px",
//       textAlign: "center",
//     },
//     statNumber: {
//       fontSize: "36px",
//       fontWeight: "bold",
//       color: "#ff0000",
//       marginBottom: "10px",
//     },
//     statLabel: {
//       fontSize: "16px",
//       color: "#ccc",
//     },

//     // Team Section
//     teamSection: {
//       marginBottom: "80px",
//     },
//     teamGrid: {
//       display: "flex",
//       flexWrap: "wrap",
//       gap: "30px",
//       justifyContent: "center",
//     },
//     teamMember: {
//       flex: "1 1 250px",
//       maxWidth: "300px",
//       backgroundColor: "#070808",
//       borderRadius: "8px",
//       overflow: "hidden",
//       boxShadow: "0 5px 15px rgba(0,0,0,0.2)",
//     },
//     teamMemberImage: {
//       width: "100%",
//       height: "auto",
//       display: "block",
//     },
//     teamMemberInfo: {
//       padding: "20px",
//     },
//     teamMemberName: {
//       fontSize: "18px",
//       fontWeight: "bold",
//       marginBottom: "5px",
//     },
//     teamMemberRole: {
//       fontSize: "14px",
//       color: "#ff0000",
//       marginBottom: "10px",
//     },
//     teamMemberBio: {
//       fontSize: "14px",
//       lineHeight: 1.6,
//       color: "#ccc",
//     },

//     // Values Section
//     valuesSection: {
//       marginBottom: "80px",
//     },
//     valuesGrid: {
//       display: "flex",
//       flexWrap: "wrap",
//       gap: "20px",
//     },
//     valueItem: {
//       flex: "1 1 calc(50% - 10px)",
//       backgroundColor: "#070808",
//       padding: "30px",
//       borderRadius: "8px",
//     },
//     valueTitle: {
//       fontSize: "20px",
//       fontWeight: "bold",
//       marginBottom: "15px",
//       color: "#ff0000",
//     },
//     valueDescription: {
//       fontSize: "16px",
//       lineHeight: 1.6,
//       color: "#ccc",
//     },

//     // CTA Section
//     ctaSection: {
//       textAlign: "center",
//       marginBottom: "40px",
//     },
//     ctaButton: {
//       display: "inline-block",
//       padding: "12px 24px",
//       backgroundColor: "#ff0000",
//       color: "#fff",
//       textDecoration: "none",
//       borderRadius: "4px",
//       fontWeight: "bold",
//       fontSize: "16px",
//       border: "none",
//       cursor: "pointer",
//       transition: "background-color 0.3s ease",
//     },

//     // Footer
//     footer: {
//       backgroundColor: "#070808",
//       color: "#ccc",
//       padding: isMobile ? "40px 0 20px" : "60px 0 20px",
//     },
//     footerContainer: {
//       width: "100%",
//       maxWidth: "1200px",
//       margin: "0 auto",
//       padding: "0 20px",
//     },
//     footerContent: {
//       display: "flex",
//       flexDirection: isMobile ? "column" : "row",
//       flexWrap: "wrap",
//       gap: isMobile ? "30px" : "40px",
//       justifyContent: "space-between",
//     },
//     footerLeft: {
//       flex: isMobile ? "1 1 100%" : "1 1 60%",
//       maxWidth: isMobile ? "100%" : "600px",
//     },
//     footerLogo: {
//       maxWidth: "150px",
//       marginBottom: "20px",
//     },
//     footerText: {
//       marginBottom: "20px",
//       lineHeight: 1.6,
//       fontSize: "14px",
//     },
//     footerLinks: {
//       display: "flex",
//       gap: "20px",
//       marginBottom: "30px",
//     },
//     footerLink: {
//       color: "#ff0000",
//       textDecoration: "none",
//       transition: "color 0.3s ease",
//     },
//     footerRight: {
//       flex: isMobile ? "1 1 100%" : "0 1 30%",
//     },
//     footerHeading: {
//       fontSize: "18px",
//       marginBottom: "15px",
//       fontWeight: "bold",
//     },
//     copyright: {
//       borderTop: "1px solid #333",
//       marginTop: "40px",
//       paddingTop: "20px",
//       textAlign: "center",
//       fontSize: "14px",
//     },
//   }

//   return (
//     <div style={styles.pageContainer}>
//       {/* Menu Bar */}
//       <div style={styles.menuBar}>
//         <div style={styles.menuContainer}>
//           <a href="/">
//             <img src="/images/logo.png" alt="Logo" style={styles.logo} />
//           </a>

//           <div style={styles.buttonsContainer}>
//             <button style={styles.signInButton}>Sign In</button>
//             <button style={styles.signUpButton}>Sign Up</button>
//           </div>
//         </div>
//       </div>

//       {/* Hero Section */}
//       <div style={styles.heroSection}>
//         <div style={styles.heroContainer}>
//           <div style={styles.heroContent}>
//             <h1 style={styles.heroHeading}>About Us</h1>
//             <p style={styles.heroSubheading}>
//               Learn about our journey, our mission, and the team behind our powerful data extraction platform.
//             </p>
//           </div>
//         </div>
//       </div>

//       {/* Divider */}
//       <svg width="100%" style={styles.divider} viewBox="0 0 1439 248" fill="none" xmlns="http://www.w3.org/2000/svg">
//         <path d="M783 116.5L0 248V0.00012207L1439 0.00012207L783 116.5Z" fill="#070808" />
//       </svg>

//       {/* About Section */}
//       <div style={styles.aboutSection}>
//         <div style={styles.aboutContainer}>
//           {/* Our Story */}
//           <div style={styles.aboutContent}>
//             <div style={styles.aboutImage}>
//               <div style={styles.aboutImageContainer}>
//                 <img
//                   src="/images/about-company.jpg"
//                   alt="Our company office"
//                   style={{ width: "100%", height: "auto", display: "block" }}
//                 />
//               </div>
//             </div>
//             <div style={styles.aboutText}>
//               <h2 style={styles.sectionHeading}>Our Story</h2>
//               <p style={styles.aboutParagraph}>
//                 Founded in 2018, <span style={styles.redText}>Uhrig</span> began with a simple mission: to make data
//                 extraction accessible to everyone. What started as a small team of three passionate developers has grown
//                 into a comprehensive platform serving thousands of businesses worldwide.
//               </p>
//               <p style={styles.aboutParagraph}>
//                 Our journey began when our founders experienced firsthand the challenges of extracting and processing
//                 data from various online sources. The existing solutions were either too complex, too expensive, or
//                 simply inefficient. This gap in the market inspired us to create a tool that combines power with
//                 simplicity.
//               </p>
//               <p style={styles.aboutParagraph}>
//                 Today, we're proud to offer a solution that has revolutionized how businesses of all sizes approach data
//                 extraction, saving them countless hours and resources while providing valuable insights.
//               </p>
//             </div>
//           </div>

//           {/* Mission Statement */}
//           <div style={styles.missionSection}>
//             <div style={styles.missionContent}>
//               <h2 style={styles.sectionHeading}>Our Mission</h2>
//               <p style={styles.missionStatement}>
//                 "To democratize data extraction by providing powerful, user-friendly tools that enable businesses to
//                 make data-driven decisions without technical barriers."
//               </p>
//             </div>
//           </div>

//           {/* Stats */}
//           <div style={styles.statsSection}>
//             <h2 style={styles.sectionHeading}>Our Impact</h2>
//             <p style={styles.sectionSubheading}>
//               We're proud of what we've accomplished and the difference we've made for our clients.
//             </p>
//             <div style={styles.statsGrid}>
//               <div style={styles.statItem}>
//                 <div style={styles.statNumber}>5,000+</div>
//                 <div style={styles.statLabel}>Active Users</div>
//               </div>
//               <div style={styles.statItem}>
//                 <div style={styles.statNumber}>50M+</div>
//                 <div style={styles.statLabel}>Data Points Processed</div>
//               </div>
//               <div style={styles.statItem}>
//                 <div style={styles.statNumber}>98%</div>
//                 <div style={styles.statLabel}>Customer Satisfaction</div>
//               </div>
//               <div style={styles.statItem}>
//                 <div style={styles.statNumber}>24/7</div>
//                 <div style={styles.statLabel}>Customer Support</div>
//               </div>
//             </div>
//           </div>

//           {/* Team */}
//           <div style={styles.teamSection}>
//             <h2 style={styles.sectionHeading}>Meet Our Team</h2>
//             <p style={styles.sectionSubheading}>The passionate individuals behind our success.</p>
//             <div style={styles.teamGrid}>
//               {/* Team Member 1 */}
//               <div style={styles.teamMember}>
//                 <img src="/placeholder.svg?height=300&width=300" alt="John Doe" style={styles.teamMemberImage} />
//                 <div style={styles.teamMemberInfo}>
//                   <h3 style={styles.teamMemberName}>John Doe</h3>
//                   <div style={styles.teamMemberRole}>CEO & Co-Founder</div>
//                   <p style={styles.teamMemberBio}>
//                     With over 15 years of experience in data science and software development, John leads our company
//                     vision and strategy.
//                   </p>
//                 </div>
//               </div>

//               {/* Team Member 2 */}
//               <div style={styles.teamMember}>
//                 <img src="/placeholder.svg?height=300&width=300" alt="Jane Smith" style={styles.teamMemberImage} />
//                 <div style={styles.teamMemberInfo}>
//                   <h3 style={styles.teamMemberName}>Jane Smith</h3>
//                   <div style={styles.teamMemberRole}>CTO & Co-Founder</div>
//                   <p style={styles.teamMemberBio}>
//                     Jane is the technical genius behind our platform, with expertise in AI and machine learning
//                     algorithms.
//                   </p>
//                 </div>
//               </div>

//               {/* Team Member 3 */}
//               <div style={styles.teamMember}>
//                 <img src="/placeholder.svg?height=300&width=300" alt="Michael Johnson" style={styles.teamMemberImage} />
//                 <div style={styles.teamMemberInfo}>
//                   <h3 style={styles.teamMemberName}>Michael Johnson</h3>
//                   <div style={styles.teamMemberRole}>Head of Product</div>
//                   <p style={styles.teamMemberBio}>
//                     Michael ensures our product meets the highest standards of usability while continuously evolving to
//                     meet customer needs.
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>

//           {/* Values */}
//           <div style={styles.valuesSection}>
//             <h2 style={styles.sectionHeading}>Our Values</h2>
//             <p style={styles.sectionSubheading}>The principles that guide everything we do.</p>
//             <div style={styles.valuesGrid}>
//               <div style={styles.valueItem}>
//                 <h3 style={styles.valueTitle}>Simplicity</h3>
//                 <p style={styles.valueDescription}>
//                   We believe powerful technology should be accessible to everyone. We strive to make complex processes
//                   simple and intuitive.
//                 </p>
//               </div>
//               <div style={styles.valueItem}>
//                 <h3 style={styles.valueTitle}>Innovation</h3>
//                 <p style={styles.valueDescription}>
//                   We're constantly pushing boundaries and exploring new ways to improve data extraction and processing.
//                 </p>
//               </div>
//               <div style={styles.valueItem}>
//                 <h3 style={styles.valueTitle}>Reliability</h3>
//                 <p style={styles.valueDescription}>
//                   Our customers depend on our platform for critical business operations. We take this responsibility
//                   seriously.
//                 </p>
//               </div>
//               <div style={styles.valueItem}>
//                 <h3 style={styles.valueTitle}>Customer-Centric</h3>
//                 <p style={styles.valueDescription}>
//                   Everything we build is designed with our users in mind. Their success is our success.
//                 </p>
//               </div>
//             </div>
//           </div>

//           {/* CTA */}
//           <div style={styles.ctaSection}>
//             <h2 style={styles.sectionHeading}>Join Our Journey</h2>
//             <p style={styles.sectionSubheading}>Ready to experience the power of effortless data extraction?</p>
//             <button style={styles.ctaButton}>Sign Up Today</button>
//           </div>
//         </div>
//       </div>

//       {/* Divider */}
//       <svg width="100%" style={styles.divider} viewBox="0 0 1439 248" fill="none" xmlns="http://www.w3.org/2000/svg">
//         <path d="M783 116.5L0 248V0.00012207L1439 0.00012207L783 116.5Z" fill="#1A1A1B" />
//       </svg>

//       {/* Footer */}
//       <div style={styles.footer}>
//         <div style={styles.footerContainer}>
//           <div style={styles.footerContent}>
//             <div style={styles.footerLeft}>
//               <div style={styles.footerLogo}>
//                 <img src="/images/logo.png" alt="Company Logo" style={{ maxWidth: "100%" }} />
//               </div>
//               <div style={styles.footerText}>
//                 Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aspernatur corrupti, voluptas facere eos,
//                 neque provident in aut, repellat architecto doloremque ipsa. Dolorum reprehenderit totam vero adipisci
//                 quos sapiente nostrum placeat!
//               </div>
//               <div style={styles.footerLinks}>
//                 <a href="/privacy-policy" style={styles.footerLink}>
//                   Privacy Policy
//                 </a>
//                 <a href="/terms-of-service" style={styles.footerLink}>
//                   Terms & Service
//                 </a>
//               </div>
//             </div>
//             <div style={styles.footerRight}>
//               <div style={styles.footerHeading}>Reach Us</div>
//               <a href="mailto:sales@uhrig.com" style={styles.footerLink}>
//                 sales@uhrig.com
//               </a>
//             </div>
//           </div>
//           <div style={styles.copyright}>Copyright © 2025 urigh.com - All Rights Reserved.</div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default About
