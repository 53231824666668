import React from 'react';
import Sidebar from '../../components/Sidebar'; // Import Sidebar component
import { Outlet } from 'react-router-dom'; // For nested routes

const DashboardLayout = () => {
  return (
    <div className="dashboard-layout">
        <div className="dashboard-side-bar">
            <Sidebar />
        </div>
        <div className="dashboard-content">
            <Outlet />    
        </div>
    </div>
  );
};

export default DashboardLayout;
