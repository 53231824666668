



import { useState } from "react"
import { Eye, EyeOff } from "lucide-react"
import logo from "../../assets/images/logo.png"
import { useNavigate } from "react-router-dom"
import config from '../../lib/config'

const Login = () => {
  const router = useNavigate()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [verificationLink, setVerificationLink] = useState("")

  const validateForm = () => {
    const newErrors = {}

    if (!email) {
      newErrors.email = ["Email is required"]
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = ["Email is invalid"]
    }

    if (!password) {
      newErrors.password = ["Password is required"]
    } 

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!validateForm()) {
      return
    }

    setIsLoading(true)
    setVerificationLink("")

    try {
      const response = await fetch(`${config.BASE_URL}/user/login/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
        }),
      })

      const data = await response.json()

      console.log(data)
      if (!response.ok) {
        console.log('if',data)
        if (data.non_field_errors && data.non_field_errors[0] === "Email is not verified." && data.verification_link) {
          router('/email-verification')
        } else if (data.non_field_errors && data.non_field_errors[0] === "Admin has not verified your account.") {
          setErrors({
            general: ["Admin has not verified your account yet. Please try again later."],
          })
        } else if(data.error && data.error === "One time payment required"){
          localStorage.setItem("access", data.access)
          router("/subscription-fee")
        }
        else {
          setErrors({
            general: [data.error && data.error || "Invalid email or password"],
          })
        }
      } else {
        // Successful login - store tokens in localStorage
        localStorage.setItem("refresh", data.refresh)
        localStorage.setItem("access", data.access)
        console.log(data.admin)
        if(data.admin && data.admin === true){
          router("/admin")
          return
        }
        // Redirect to dashboard or home page
        router("/dashboard")
      }
    } catch (error) {
      setErrors({
        general: ["An error occurred. Please try again."],
      })
      console.error("Login error:", error)
    } finally {
      setIsLoading(false)
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const renderErrors = (field) => {
    if (errors[field]) {
      return (
        <div className="error-message" style={{color:"red"}}>
          {errors[field].map((error, index) => (
            <p key={index}>{error}</p>
          ))}
        </div>
      )
    }
    return null
  }

  const handleVerifyEmail = () => {
    if (verificationLink) {
      window.location.href = verificationLink
    }
  }

  return (
    <div className="page-wrapper">
      <div className="form-container">
        {/* Logo */}
        <div className="logo-wrapper">
          <img src={logo || "/placeholder.svg"} alt="Logo" className="logo" />
        </div>
        <h2>Login</h2>

        {errors.general && renderErrors("general")}

        {verificationLink && (
          <div className="verification-link">
            <button onClick={handleVerifyEmail} className="verify-btn">
              Verify Email Now
            </button>
          </div>
        )}

        <form onSubmit={handleSubmit}>
          {/* Email Input */}
          <div style={{ marginBottom: "20px" }}>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="input-field"
              disabled={isLoading}
            />
            {renderErrors("email")}
          </div>

          {/* Password Input */}
          <div style={{ marginBottom: "20px", position: "relative" }}>
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="input-field"
              disabled={isLoading}
            />
            <button type="button" onClick={togglePasswordVisibility} className="password-toggle" tabIndex={-1}>
              {showPassword ? (
                <EyeOff size={20} className="password-icon" />
              ) : (
                <Eye size={20} className="password-icon" />
              )}
            </button>
            {renderErrors("password")}
          </div>

          {/* Submit Button */}
          <div style={{ marginBottom: "20px" }}>
            <button type="submit" className="submit-btn" disabled={isLoading}>
              {isLoading ? "Logging in..." : "Login"}
            </button>
          </div>
        </form>

        {/* Links */}
        <div className="links">
          <a href="/forgot-password">Forgot Password?</a>
          <br />
          <a href="/register">Don't have an account? Register</a>
        </div>
      </div>
    </div>
  )
}

export default Login

