import React, { useState } from 'react';
import MenuBar from '../components/MenuBar'; // Import Sidebar component
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation

const Home = () => {

  return (
    <div className="site-page-wrapper">
      <div className="site-page-wrapper-inner">
        <div className="hero-section">
            <MenuBar />
            <div className="custom-container">
                <div className="hero-section-main">
                    <div className="hero-section-content">
                        <div className="hero-section-content-text">
                            Effortless Data Extraction, Simplified
                        </div>
                        <div className="hero-section-content-cta">
                            <a href="/register" className='site-cta filled'>Sign Up Now</a>
                        </div>
                    </div>
                    <div className="hero-section-mokup">
                        <div className="hero-section-mokup-inner">
                            <div className="hero-section-mokup-box">
                                <div className="hero-section-mokup-box-top">
                                    <span className="dots red"></span>
                                    <span className="dots yellow"></span>
                                    <span className="dots green"></span>
                                </div>
                                <div className="hero-section-mokup-screen">
                                    <img src="/images/hero-section.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <svg width="100%" className='devider' viewBox="0 0 1439 248" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M783 116.5L0 248V0.00012207L1439 0.00012207L783 116.5Z" fill="#070808"/>
        </svg>
        <div className="site-feature">
            <h2>Features</h2>
            <p className='white-text' style={{textAlign:'center'}}>Unlock the full potential of data extraction with our powerful, easy-to-use scraping tool.</p>
            <div className="custom-container">
                <div className="feature-grid">
                    <div className="single-feature">
                        <div className="feature-image">
                            <img src="images/feature-01.png" alt="" />
                        </div>
                        <div className="feature-text">
                            <h3>Easy to use interface</h3>
                            <p>Our platform is built with an easy-to-use interface, ensuring that users of all skill levels can quickly navigate and perform scraping tasks without a steep learning curve. Whether you're a first-time user or an experienced data professional, the intuitive design helps you get started right away.</p>
                        </div>
                    </div>
                    <div className="single-feature">
                        <div className="feature-image">
                            <img src="images/feature-02.png" alt="" />
                        </div>
                        <div className="feature-text">
                            <h3>Powerful Scraping Capabilities</h3>
                            <p>Behind the simplicity lies powerful scraping capabilities. With support for leading platforms like Amazon and eBay, our tool efficiently handles complex data extraction tasks, offering speed, accuracy, and scalability. It’s designed to retrieve all the essential product information you need with minimal effort.</p>
                        </div>
                    </div>
                    <div className="single-feature">
                        <div className="feature-image">
                            <img src="images/feature-03.png" alt="" />
                        </div>
                        <div className="feature-text">
                            <h3>Data Cleaning and Transformation</h3>
                            <p>Once the data is collected, our data cleaning and transformation features step in to refine your results. Automatically remove duplicates, standardize formats, and apply transformations to create clean, structured datasets ready for immediate use. This ensures your data is not only complete but also perfectly organized for analysis, reporting, or integration into other systems.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <svg width="100%" className='devider-02' viewBox="0 0 1439 248" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M783 116.5L0 248V0.00012207L1439 0.00012207L783 116.5Z" fill="#1A1A1B"/>
        </svg>
        <div className="site-workflow">
            <div className="custom-container">
                <h2>Work Flow</h2>
                <p className='white-text' style={{textAlign:'center'}}>Unlock the full potential of data extraction with our powerful, easy-to-use scraping tool.</p>
                <img src="images/flow.png" alt="" style={{width:'100%'}}/>
            </div>
        </div>
        <svg width="100%" className='devider-03' viewBox="0 0 1439 248" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M783 116.5L0 248V0.00012207L1439 0.00012207L783 116.5Z" fill="#1A1A1B"/>
        </svg>
        <div className="site-pricing">
            <h1 style={{color : '#fff',textAlign : 'center'}}>Let's Start it today at 9.99€/month</h1>
            <p style={{textAlign:'center'}}>
                <a href="/register" className='site-cta filled'>Sign Up Today</a>
            </p>
        </div>
        <div className="footer">
            <div className="custom-container">
                <div className="footer-main">
                    <div className="footer-logo">
                        <div className="footer-logo-image">
                            <img src="/images/logo.png" alt="" />
                        </div>
                        <div className="footer-bottom-text">
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aspernatur corrupti, voluptas facere eos, neque provident in aut, repellat architecto doloremque ipsa. Dolorum reprehenderit totam vero adipisci quos sapiente nostrum placeat!
                        </div>
                        <div className="footer-docs">
                            <a href="/privacy-policy" className="link">Privacy Policy</a>
                            <a href="/terms-of-service" className="link">Terms & Service</a>
                        </div>
                    </div>
                    <div className="footer-contact-us">
                        <div className="footer-heading">Reach Us</div>
                        <a href="mailto:sales@uhrig.com" className="link">sales@uhrig.com</a>
                    </div>
                </div>
                <div className="copyright">Copyright © 2025 urigh.com - All Rights Reserved.</div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Home;