
import { useContext, useState } from "react"
import { AppContext } from "../../context/AppContext"
import { CheckCircle, AlertCircle, Send } from "lucide-react"
import config from '../../lib/config'
import { useNavigate } from "react-router-dom"

const FileSummary = () => {
  const { total_records, estimation_cost, estimation_time, file, jobName, setFile, setJobName } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")
  const [isSuccess, setIsSuccess] = useState(false)
  const navigate = useNavigate()

  const handleSubmit = async () => {
    if (!file || !jobName) {
      setMessage("Please select a file and enter a job name.")
      setIsSuccess(false)
      return
    }

    const token = localStorage.getItem("access")

    if (!token) {
      setMessage("Access denied")
      setIsSuccess(false)
      return
    }

    setLoading(true)
    setMessage("")

    const formData = new FormData()
    formData.append("file", file)
    formData.append("job_name", jobName)
    formData.append("total_records", Number(total_records))

    try {
      const response = await fetch(`${config.BASE_URL}/api/jobs/runScraper/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })

      if(response.status === 401){
        navigate('/')
      }

      const data = await response.json()

      if (data.success) {
        setMessage(`Job started successfully! Job ID: ${data.job.job_id}`)
        setIsSuccess(true)
        setFile(null)
        setJobName("")
      } else {
        setMessage(`Error: ${data.error}`)
        setIsSuccess(false)
      }
    } catch (error) {
      setMessage("Failed to submit the job. Please try again.")
      setIsSuccess(false)
    } finally {
      setLoading(false)
    }
  }

  const containerStyle = {
    padding: "20px",
    color: "#fff",
    maxWidth: "1200px",
    margin: "0 auto",
  }

  const titleStyle = {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
  }

  const cardStyle = {
    backgroundColor: "#121212",
    borderRadius: "8px",
    padding: "24px",
    marginTop: "20px",
  }

  const summaryRowStyle = {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 0",
    borderBottom: "1px solid #333",
  }

  const labelStyle = {
    color: "#999",
    fontSize: "16px",
  }

  const valueStyle = {
    color: "#fff",
    fontSize: "16px",
    fontWeight: "bold",
  }

  const bottomDivStyle = {
    marginTop: "24px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }

  const noteStyle = {
    color: "#999",
    fontSize: "14px",
    marginBottom: "20px",
    textAlign: "center",
  }

  const buttonStyle = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "12px 24px",
    backgroundColor: "#E84118",
    border: "none",
    borderRadius: "4px",
    color: "white",
    cursor: loading ? "not-allowed" : "pointer",
    fontSize: "16px",
    opacity: loading ? 0.7 : 1,
    fontWeight: "bold",
  }

  const messageContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    marginTop: "16px",
  }

  const messageStyle = {
    color: isSuccess ? "#52C41A" : "#FF4D4F",
    fontSize: "14px",
  }

  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>Job Summary</h1>

      <div style={cardStyle}>
        <div style={summaryRowStyle}>
          <span style={labelStyle}>Total products</span>
          <span style={valueStyle}>{total_records}</span>
        </div>
        <div style={summaryRowStyle}>
          <span style={labelStyle}>Estimation Coins</span>
          <span style={valueStyle}>{estimation_cost}</span>
        </div>
        <div style={{ ...summaryRowStyle, borderBottom: "none" }}>
          <span style={labelStyle}>Estimated Time</span>
          <span style={valueStyle}>{estimation_time}</span>
        </div>

        <div style={bottomDivStyle}>
          <p style={noteStyle}>Note: This is an estimation cost. Final cost will appear after job completion.</p>

        {
          !isSuccess &&
          <button style={buttonStyle} onClick={handleSubmit} disabled={loading}>
            {loading ? "Submitting..." : "Submit"}
            {!loading && <Send size={16} />}
          </button>
          }

          {message && (
            <div style={messageContainerStyle}>
              {isSuccess ? <CheckCircle size={16} color="#52C41A" /> : <AlertCircle size={16} color="#FF4D4F" />}
              <p style={messageStyle}>{message}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default FileSummary

