"use client"

import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { CreditCard, Plus, ArrowUpRight } from "lucide-react"
import { convertTimestamp } from "../../lib/methods"
import config from '../../lib/config'

const Wallet = () => {
  const [currentCard, setCurrentCard] = useState({})
  const [transactions, setTransactions] = useState([])
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState({
    card: true,
    transactions: true,
    userData: true,
  })
  const [error, setError] = useState({
    card: null,
    transactions: null,
    userData: null,
  })
  const navigate = useNavigate()

  const fetchCardByPaymentId = async () => {
    const token = localStorage.getItem("access")

    if (!token) {
      setError((prev) => ({ ...prev, card: "No access token found" }))
      setLoading((prev) => ({ ...prev, card: false }))
      return
    }

    try {
      const response = await fetch(`${config.BASE_URL}/wallet/fetchCardByPaymentId/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })

      if(response.status === 401){
        navigate('/')
      }

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      const data = await response.json()
      setCurrentCard(data)
    } catch (error) {
      console.error("Error fetching card data:", error)
      setError((prev) => ({ ...prev, card: "Load balance" }))
    } finally {
      setLoading((prev) => ({ ...prev, card: false }))
    }
  }

  const fetchTransactions = async () => {
    const token = localStorage.getItem("access")

    if (!token) {
      setError((prev) => ({ ...prev, transactions: "No access token found" }))
      setLoading((prev) => ({ ...prev, transactions: false }))
      return
    }

    try {
      const response = await fetch(`${config.BASE_URL}/wallet/listOfAllCharges/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })

      if(response.status === 401){
        navigate('/')
      }

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }



      const { list } = await response.json()
      console.log(list)
      setTransactions(list || [])
    } catch (error) {
      console.error("Error fetching transactions:", error)
      setError((prev) => ({ ...prev, transactions: "No Transactions" }))
    } finally {
      setLoading((prev) => ({ ...prev, transactions: false }))
    }
  }

  const fetchUserData = async () => {
    const token = localStorage.getItem("access")

    if (!token) {
      setError((prev) => ({ ...prev, userData: "No access token found" }))
      setLoading((prev) => ({ ...prev, userData: false }))
      return
    }

    try {
      const response = await fetch(`${config.BASE_URL}/wallet/getWalletData/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })

      if(response.status === 401){
        navigate('/')
      }

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      const data = await response.json()
      if (data.success) {
        setUser(data.wallet)
      }
    } catch (error) {
      console.error("Error fetching user data:", error)
      setError((prev) => ({ ...prev, userData: "Load Balance" }))
    } finally {
      setLoading((prev) => ({ ...prev, userData: false }))
    }
  }

  useEffect(() => {
    fetchUserData()
    fetchCardByPaymentId()
    fetchTransactions()
  }, [])

  // Calculate usage percentage
  const usagePercentage = 80 // This should be calculated based on actual data

  // Styles
  const containerStyle = {
    padding: "20px",
    color: "#fff",
    maxWidth: "1200px",
    margin: "0 auto",
  }

  const titleStyle = {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
    textAlign: "left",
  }

  const summaryContainerStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gap: "20px",
    marginBottom: "30px",
  }

  const cardStyle = {
    backgroundColor: "#121212",
    borderRadius: "8px",
    padding: "24px",
  }

  const cardHeaderStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  }

  const cardTitleStyle = {
    fontSize: "18px",
    fontWeight: "bold",
  }

  const addBalanceButtonStyle = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "8px 16px",
    backgroundColor: "#E84118",
    border: "none",
    borderRadius: "4px",
    color: "white",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "bold",
  }

  const balanceInfoStyle = {
    marginBottom: "24px",
  }

  const balanceLabelStyle = {
    display: "block",
    color: "#999",
    fontSize: "14px",
    marginBottom: "8px",
  }

  const balanceAmountStyle = {
    display: "block",
    fontSize: "28px",
    fontWeight: "bold",
  }

  const usageInfoStyle = {
    marginTop: "16px",
  }

  const usageLabelStyle = {
    display: "block",
    color: "#999",
    fontSize: "12px",
    marginBottom: "4px",
  }

  const usageValueStyle = {
    display: "block",
    fontSize: "14px",
    marginBottom: "8px",
  }

  const usageBarContainerStyle = {
    height: "8px",
    backgroundColor: "#333",
    borderRadius: "4px",
    overflow: "hidden",
  }

  const usageBarFillStyle = {
    height: "100%",
    backgroundColor: "#E84118",
    width: `${usagePercentage}%`,
  }

  const paymentMethodStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexWrap: "wrap",
    gap: "16px",
  }

  const cardInfoStyle = {
    flex: "1",
    minWidth: "200px",
  }

  const cardTypeStyle = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    marginBottom: "8px",
    fontSize: "16px",
    fontWeight: "bold",
  }

  const cardNumberStyle = {
    display: "block",
    fontSize: "14px",
    marginBottom: "4px",
  }

  const cardExpiryStyle = {
    display: "block",
    fontSize: "14px",
    color: "#999",
  }

  const changeButtonStyle = {
    padding: "8px 16px",
    backgroundColor: "transparent",
    border: "1px solid #444",
    borderRadius: "4px",
    color: "#fff",
    cursor: "pointer",
  }

  const tableContainerStyle = {
    overflowX: "auto",
  }

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
  }

  const thStyle = {
    textAlign: "left",
    padding: "12px 16px",
    borderBottom: "1px solid #333",
    color: "#999",
    fontSize: "14px",
  }

  const tdStyle = {
    padding: "12px 16px",
    borderBottom: "1px solid #222",
    fontSize: "14px",
    textAlign: "left",
  }

  const emptyStateStyle = {
    textAlign: "center",
    padding: "24px",
    color: "#999",
  }

  const loadingStyle = {
    textAlign: "center",
    padding: "16px",
    color: "#999",
  }

  const errorStyle = {
    textAlign: "center",
    padding: "16px",
    color: "#FF4D4F",
  }

  const positiveAmountStyle = {
    color: "#52C41A",
    fontWeight: "bold",
  }

  return (
    <div style={containerStyle}>
      <h2 style={titleStyle}>My Wallet</h2>

      <div style={summaryContainerStyle}>
        <div style={cardStyle}>
          <div style={cardHeaderStyle}>
            <h3 style={cardTitleStyle}>Coin Summary</h3>
            <Link to="/wallet/add-balance" style={{ textDecoration: "none" }}>
              <button style={addBalanceButtonStyle}>
                <Plus size={16} />
                Add Balance
              </button>
            </Link>
          </div>

          {loading.userData ? (
            <div style={loadingStyle}>Loading balance...</div>
          ) : error.userData ? (
            <div style={errorStyle}>{error.userData}</div>
          ) : (
            <>
              <div style={balanceInfoStyle}>
                <span style={balanceLabelStyle}>Remaining Balance</span>
                <span style={balanceAmountStyle}>€ {user?.balance ?? 0}</span>
              </div>
              {/* <div style={usageInfoStyle}>
                <span style={usageLabelStyle}>USAGE</span>
                <span style={usageValueStyle}>20,000 out of 25,000</span>
                <div style={usageBarContainerStyle}>
                  <div style={usageBarFillStyle} />
                </div>
              </div> */}
            </>
          )}
        </div>

        <div style={cardStyle}>
          <h3 style={cardTitleStyle}>Payment Method</h3>

          {loading.card ? (
            <div style={loadingStyle}>Loading card details...</div>
          ) : error.card ? (
            <div style={errorStyle}>{error.card}</div>
          ) : (
            <div style={paymentMethodStyle}>
              <div style={cardInfoStyle}>
                <div style={cardTypeStyle}>
                  <CreditCard size={20} />
                  <span>{currentCard?.card?.brand || "Credit"} Card</span>
                </div>
                <span style={cardNumberStyle}>**** **** **** {currentCard?.card?.last4 || "****"}</span>
                <span style={cardExpiryStyle}>
                  Expiry on: {currentCard?.card?.exp_month || "MM"}/{currentCard?.card?.exp_year || "YY"}
                </span>
              </div>
              <Link to="/wallet/payment-method" style={{ textDecoration: "none" }}>
                <button style={changeButtonStyle}>Change</button>
              </Link>
            </div>
          )}
        </div>
      </div>

      <h4 style={titleStyle}>Coin Transaction Logs</h4>

      <div style={cardStyle}>
        {loading.transactions ? (
          <div style={loadingStyle}>Loading transactions...</div>
        ) : error.transactions ? (
          <div style={errorStyle}>{error.transactions}</div>
        ) : (
          <div style={tableContainerStyle}>
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th style={thStyle}>Transaction ID</th>
                  <th style={thStyle}>Date</th>
                  <th style={thStyle}>Coins</th>
                  <th style={thStyle}>Type</th>
                  <th style={thStyle}>Balance</th>
                </tr>
              </thead>
              <tbody>
                {transactions.length > 0 ? (
                  transactions.map((data, index) => (
                    <tr key={index}>
                      <td style={tdStyle}>{data.transactionId?.slice(0, 10)} .......</td>
                      <td style={tdStyle}>{data.created_at.slice(0,10)}</td>
                      {
                        data.transaction_type === "Deposit" ?
                          <td style={{ ...tdStyle, ...positiveAmountStyle }}>
                          {data.coins ? `+ ${data.coins}` : "N/A"}
                        </td>
                        :
                        <td style={{ ...tdStyle,color:"red",fontWeight: "bold" }}>
                          {data.coins ? `- ${data.coins}` : "N/A"}
                        </td>
                      }
                      
                    
                      <td style={tdStyle}>
                        <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                          {data.transaction_type}
                        </div>
                      </td>
                      <td style={tdStyle}>
                        {data?.amount ? `€ ${Math.floor(data.amount)}` : "N/A"}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" style={emptyStateStyle}>
                      No transactions found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}

export default Wallet

