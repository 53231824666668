"use client"

import { useState, useEffect } from "react"
import { Lock, Mail, Phone, Euro, Calendar, AlertTriangle } from "lucide-react"
import { useNavigate } from "react-router-dom"
import config from '../../lib/config'

function Settings() {
  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmNewPassword, setConfirmNewPassword] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [notifications, setNotifications] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [passwordError, setPasswordError] = useState("")
  const [passwordSuccess, setPasswordSuccess] = useState("")
  const [phoneSuccess, setPhoneSuccess] = useState("")
  const [phoneError, setPhoneError] = useState("")
  const [subscription,setSubscription] = useState()
  const navigate = useNavigate()

  const token = localStorage.getItem("access")

  useEffect(() => {
    fetchUserProfile()
  }, [])



function formatUnixToDateOnly(unixTimestamp) {
  const date = new Date(unixTimestamp * 1000);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
}

  const fetchUserProfile = async () => {
    if (!token) {
      setError("Authentication token is missing")
      setLoading(false)
      return
    }


    try {
      setLoading(true)
      const response = await fetch(`${config.BASE_URL}/user/profile/update/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if(response.status === 401){
        navigate('/')
      }

      if (!response.ok) {
        throw new Error("Failed to fetch profile data")
      }


      const data = await response.json()
      console.log(data)
      if(data?.subscription.status !== "active"){
        window.location.href = "/login"
        return
      }
      setEmail(data.email || "")
      setPhone(data.phone_number || "")
      setNotifications(data.notifications || false)
      setSubscription(data?.subscription)
    } catch (err) {
      setError(err.message || "An error occurred while fetching profile data")
    } finally {
      setLoading(false)
    }
  }

  const handlePasswordChange = async () => {
    // Reset previous messages
    setPasswordError("")
    setPasswordSuccess("")

    // Validate passwords
    if (!currentPassword) {
      setPasswordError("Current password is required")
      return
    }

    if (!newPassword) {
      setPasswordError("New password is required")
      return
    }

    if (newPassword !== confirmNewPassword) {
      setPasswordError("New passwords do not match")
      return
    }

    // if (newPassword.length < 3) {
    //   setPasswordError("Password must be at least 8 characters long")
    //   return
    // }

    try {
      const response = await fetch(`${config.BASE_URL}/user/profile/update/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          old_password: currentPassword,
          new_password: newPassword,
        }),
      })

      if(response.status === 401){
        navigate('/')
      }

      const data = await response.json()

      if (response.ok && data.message) {
        setPasswordSuccess("Password changed successfully")
        // Clear password fields
        setCurrentPassword("")
        setNewPassword("")
        setConfirmNewPassword("")
      } else {
        setPasswordError(data.error || "Error changing password")
      }
    } catch (err) {
      setPasswordError("An error occurred. Please try again.")
    }
  }

  
  const cancelSubscription = async () => {
    if (!token) {
      setError("Authentication token is missing");
      setLoading(false);
      localStorage.clear()
      window.location.href = "/login"
      return;
    }
  
    try {
      setLoading(true);
      const response = await fetch(`${config.BASE_URL}/user/CancelSubscription/`, {
        method: "GET", // Assuming POST method for cancellation
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if(response.status === 401){
        navigate('/')
      }
  
      
      if (!response.ok) {
        throw new Error("Failed to cancel subscription");
      }
  
      const data = await response.json();
  
      if (response.status === 200) {
        if(data.success === true && data.status === 'inactive' ){
          window.location.href = "/login"
          return
        }
      if(data.success === true && data.status === 'active' ){
        alert(`${data.message}`);
        fetchUserProfile()// Clear subscription data if cancellation was successful
      }
      if(data.success === false){
        alert(`Cannot cancel subscription right now.`);
      }
      }
    } catch (err) {
      setError(err.message || "An error occurred while cancelling the subscription");
    } finally {
      setLoading(false);
    }
  };
  

  const handlePhoneChange = async () => {
    setPhoneSuccess("")
    setPhoneError("")

    if (!phone) {
      setPhoneError("Phone number cannot be empty")
      return
    }

    try {
      const response = await fetch(`${config.BASE_URL}/user/profile/phone/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ phone_number: phone }),
      })

      if(response.status === 401){
        navigate('/')
      }

      const data = await response.json()
      if (response.ok) {
        setPhoneSuccess("Phone number updated successfully")
      } else {
        setPhoneError(data.error || "Failed to update phone number")
      }
    } catch (err) {
      setPhoneError("An error occurred. Please try again.")
    }
  }

  // Styles
  const containerStyle = {
    padding: "20px",
    color: "#fff",
    maxWidth: "1200px",
    margin: "0 auto",
  }

  const titleStyle = {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
  }

  const sectionStyle = {
    backgroundColor: "#121212",
    borderRadius: "8px",
    padding: "24px",
    marginBottom: "20px",
  }

  const sectionTitleStyle = {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "16px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  }

  const inputContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    marginBottom: "16px",
  }

  const inputStyle = {
    width: "100%",
    padding: "12px 16px",
    backgroundColor: "#1E1E1E",
    border: "1px solid #333",
    borderRadius: "4px",
    color: "#fff",
    fontSize: "14px",
  }

  const readOnlyInputStyle = {
    ...inputStyle,
    backgroundColor: "#1A1A1A",
    cursor: "not-allowed",
  }

  const readAndWriteInputStyle = {
    ...inputStyle,
    backgroundColor: "#1A1A1A",
  }

  const buttonContainerStyle = {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "16px",
  }

  const buttonStyle = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "10px 20px",
    backgroundColor: "#E84118",
    border: "none",
    borderRadius: "4px",
    color: "white",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "bold",
  }

  const cancelButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#FF4D4F",
  }

  const notificationToggleStyle = {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    marginBottom: "16px",
  }

  const checkboxStyle = {
    width: "20px",
    height: "20px",
    accentColor: "#E84118",
  }

  const detailRowStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 0",
    borderBottom: "1px solid #333",
  }

  const labelStyle = {
    color: "#999",
    fontSize: "14px",
  }

  const valueStyle = {
    fontSize: "14px",
    fontWeight: "bold",
  }

  const errorMessageStyle = {
    color: "#FF4D4F",
    fontSize: "14px",
    marginTop: "8px",
    padding: "8px",
    backgroundColor: "rgba(255, 77, 79, 0.1)",
    borderRadius: "4px",
    display: passwordError ? "flex" : "none",
    alignItems: "center",
    gap: "8px",
  }

  const successMessageStyle = {
    color: "#52C41A",
    fontSize: "14px",
    marginTop: "8px",
    padding: "8px",
    backgroundColor: "rgba(82, 196, 26, 0.1)",
    borderRadius: "4px",
    display: passwordSuccess ? "flex" : "none",
    alignItems: "center",
    gap: "8px",
  }

  const loadingStyle = {
    textAlign: "center",
    padding: "40px 20px",
    color: "#999",
  }

  const errorStyle = {
    textAlign: "center",
    padding: "40px 20px",
    color: "#FF4D4F",
  }

  if (loading)
    return (
      <div style={containerStyle}>
        <div style={loadingStyle}>Loading settings...</div>
      </div>
    )
  if (error)
    return (
      <div style={containerStyle}>
        <div style={errorStyle}>Error: {error}</div>
      </div>
    )

  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>Settings</h1>

      <div style={sectionStyle}>
        <h4 style={sectionTitleStyle}>
          <Lock size={20} />
          Password
        </h4>
        <div style={inputContainerStyle}>
          <input
            type="password"
            placeholder="Current Password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            style={inputStyle}
          />
          <input
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            style={inputStyle}
          />
          <input
            type="password"
            placeholder="Confirm New Password"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            style={inputStyle}
          />
        </div>
        <div style={errorMessageStyle}>
          <AlertTriangle size={16} />
          {passwordError}
        </div>
        <div style={successMessageStyle}>
          <Lock size={16} />
          {passwordSuccess}
        </div>
        <div style={buttonContainerStyle}>
          <button onClick={handlePasswordChange} style={buttonStyle}>
            Save
          </button>
        </div>
      </div>

      <div style={sectionStyle}>
        <h4 style={sectionTitleStyle}>
          <Mail size={20} />
          Email
        </h4>
        <input type="email" value={email} readOnly style={readOnlyInputStyle} />
      </div>

      {/* <div style={sectionStyle}>
        <h4 style={sectionTitleStyle}>
          <Phone size={20} />
          Phone Number
        </h4>
        <input type="tel" value={phone} readOnly style={readOnlyInputStyle} />
      </div> */}

      <div style={sectionStyle}>
        <h4 style={sectionTitleStyle}>
          <Phone size={20} /> Phone Number
        </h4>
        <input
          type="tel"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          style={readAndWriteInputStyle}
        />
        <div style={buttonContainerStyle}>
        <button style={buttonStyle} onClick={handlePhoneChange}>Save</button>
        </div>
        {phoneError && <div>{phoneError}</div>}
        {phoneSuccess && <div>{phoneSuccess}</div>}
      </div>

      {/* <div style={sectionStyle}>
        <h4 style={sectionTitleStyle}>
          <Bell size={20} />
          Notifications
        </h4>
        <div style={notificationToggleStyle}>
          <input type="checkbox" checked={notifications} onChange={handleNotificationsChange} style={checkboxStyle} />
          <span>In-app notifications</span>
        </div>
        <div style={buttonContainerStyle}>
          <button style={buttonStyle}>Save</button>
        </div>
      </div> */}


      {
        subscription &&
        subscription.status === "active" &&
      
      <div style={sectionStyle}>
        <div style={{display:"flex",justifyContent:"space-between","alignContent":"center"}}>
        <h4 style={sectionTitleStyle}>
          <Calendar size={20} />
          Monthly Subscription
        </h4>
        <h4 style={sectionTitleStyle}>
          {subscription.price}
          <Euro size={20} />
        </h4>
          </div>
        <div>
          <div style={detailRowStyle}>
            <span style={labelStyle}>Subscription Date</span>
            <span style={valueStyle}>{formatUnixToDateOnly(subscription.current_period_start)}</span>
          </div>
          <div style={detailRowStyle}>
            <span style={labelStyle}>Subscription Ends</span>
            <span style={valueStyle}>{formatUnixToDateOnly(subscription.current_period_end)}</span>
          </div>
          {
            subscription.cancel_at_period_end === false &&
          <div style={{ ...detailRowStyle, borderBottom: "none" }}>
            <span style={labelStyle}>Cancel Subscription</span>
            <button onClick={cancelSubscription} style={cancelButtonStyle}>
              {loading ? 'Loading...': 'Cancel'}
            </button>
          </div>
          }
        </div>
      </div>  
      }

    </div>
  )
}

export default Settings

